<template>
    <div>
        <div class="back">
            <div>
                <img alt="" :src="require('@/assets/image/返回.png')" @click="goBack" />
            </div>
        </div>
        <div class="scroll-list" v-infinite-scroll="load" infinite-scroll-immediate="disabled" v-if="list && list.length > 0">
            <div v-for="(item, index) in list" :key="index">
                <div v-if="index == statusIndex"><el-divider content-position="center">已读消息</el-divider></div>
                <div class="show-item">
                    <el-card class="box-card" shadow="always">
                        <div class="show-card-item-title">{{item.content}}</div>
                        <div class="show-card-item">
                            <div class="show-card-item-left">任务名称：</div>
                            <div class="show-card-item-right">{{ item.taskName }}</div>
                        </div>
                        <div class="show-card-item">
                            <div class="show-card-item-left">所属项目：</div>
                            <div class="show-card-item-right">{{ item.projectName }}</div>
                        </div>
                        <div class="show-card-item">
                            <div class="show-card-item-left">任务截止时间：</div>
                            <div class="show-card-item-right">{{ item.end }}</div>
                        </div>
                        <div class="show-card-item">
                            <div class="show-card-item-left">发布人：</div>
                            <div class="show-card-item-right">{{ item.sendUsername }}</div>
                        </div>
                    </el-card>
                </div>
            </div>
        </div>
        <p v-if="loading">加载中...</p>
        <div v-if="noMore">
            <el-empty image="https://oa.mejour.cn/logo/LOGO@2x.png" description="暂无数据"></el-empty>
        </div>
        <div v-if="noMoreLoading">
            <el-empty image="https://oa.mejour.cn/logo/LOGO@2x.png" description="暂无数据"></el-empty>
        </div>
        <div style="height: 40px;"></div>
    </div>
</template>
<script>
import API from "@/api/api_index";
export default {
    name: 'notice_list',
    props: {},
    components: {
    },
    data() {
        return {
            version: 1,
            startTime: new Date(new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate() + ' 00:00:00'),
            endTime: new Date(new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate() + ' 23:59:59'),
            list: [],
            statusType: 2,
            statusIndex: 0,
            pageNum: 1,
            pageSize: 10,
            loading: false,
            noMoreLoading: false,
        }
    },
    computed: {
        noMore() {
            return this.list >= 20
        },
        disabled() {
            return this.loading || this.noMore
        }
    },
    created() {
        this.getNoticeList()
    },
    mounted() { },
    methods: {
        goBack(){
            if (this.$router.history._startLocation == this.$route.fullPath) {
                this.$router.push({
                    path: '/',
                    query: {}
                })
                location.reload()
            } else {
                this.$router.back(-1)
            }
        },
        load(){
            this.loading = true
            this.pageNum += 1
            this.getNoticeList()
        },
        doUpdateNotice() {
            let that = this
            if (!localStorage.getItem("oa_token")) {
                return;
            }
            let params = {};
            API.doUpdateNotice(params).then(res => {
                if (res.key === 1000) {
                    this.version += 1
                } else {
                    this.$message({
                        type: "warning",
                        message: res.message
                    });
                }
            });
        },
        getNoticeList() {
            let that = this
            if (!localStorage.getItem("oa_token")) {
                return;
            }
            let params = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
            };
            API.getNoticeList(params).then(res => {
                if (res.key === 1000) {
                    var list = res.result.list
                    let statusIndex = null //查询已读的数据是从第几条开始的
                    list.forEach((item,index) => {
                        if(!statusIndex && statusIndex != 0 && item.status == 2){
                            statusIndex = index
                        }
                        item.end = that.$moment(item.endTime).format(
                            "YYYY-MM-DD HH:mm"
                        )
                    })
                    if(list.length == 0){
                        this.noMoreLoading = true
                    }
                    this.statusIndex = statusIndex
                    this.list = this.list.concat(list)
                    this.loading = false
                    if(list && list.length > 0 && this.version == 1){
                        this.doUpdateNotice()
                    }
                } else {
                    this.$message({
                        type: "warning",
                        message: res.message
                    });
                }
            });
        }
    },
}
</script>
<style scoped>
.show-item {
    padding-top: 10px;
    width: 90%;
    margin: 1px auto;
}

.box-card {}

.clearfix:before,
.clearfix:after {
    display: table;
    content: "";
}

.clearfix:after {
    clear: both
}

.card-show-color {
    list-style-type: circle;
    height: 10px;
    width: 10px;
}

.show-card-item {
    display: inline-flex;
    width: 100%;
    margin-top: 10px;
}

.show-card-item-left {
    font-size: 15px;
    font-weight: bold;
    width: 40%;
    text-align: left;
}

.show-card-item-right {
    width: 60%;
    text-align: left;
}
.show-card-button{
    display: inline-flex;
    margin-top: 10px;
}
.show-card-item-title{
    font-size: 20px;
    font-weight: bold;
    margin: 10px auto;
}
.scroll-list{
    height: calc(100vh);
    overflow: auto;
}
.back{
    height: 20pt;
    text-align: left;
    margin-left: 20pt;
    margin-top: -20pt;
}
.back img{
    height: 20pt;
    width: 20pt;
    cursor: pointer;
}
</style>