<template>
    <div>
        <div class="back">
            <div>
                <img alt="" :src="require('@/assets/image/返回.png')" @click="goBack" />
            </div>
        </div>
        <div class="scroll-list" v-if="list && list.length > 0">
            <div v-for="(item, index) in list" :key="index">
                <div class="show-item">
                    <div class="team-box-card" @click="goTeamUser(item)">
                        <div class="show-card-item-title">{{ item.content }}</div>
                        <div class="show-card-item">
                            <div class="show-card-item-text">
                                <div class="show-card-item-left"></div>
                                <div class="show-card-item-right">{{ item.sysValueName }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <el-empty image="https://oa.mejour.cn/logo/LOGO@2x.png" description="暂无数据"></el-empty>
        </div>
        <div style="height: 40px;"></div>
    </div>
</template>
<script>
import API from "@/api/api_index";
export default {
    name: 'team_list',
    props: {},
    components: {
    },
    data() {
        return {
            version: 1,
            startTime: new Date(new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate() + ' 00:00:00'),
            endTime: new Date(new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate() + ' 23:59:59'),
            list: [],
            pageNum: 1,
            pageSize: 10,
        }
    },
    computed: {
    },
    created() {
        this.getSysLabelValueList()
    },
    mounted() {
        if (window.outerWidth > 750) {
        } else {
            var sheet = document.createElement('style');
            sheet.innerHTML = ".team-box-card { width: 75% !important; }";
            document.body.appendChild(sheet);
        }
    },
    methods: {
        goBack() {
            if (this.$router.history._startLocation == this.$route.fullPath) {
                this.$router.push({
                    path: '/',
                    query: {}
                })
                location.reload()
            } else {
                this.$router.back(-1)
            }
        },
        // 跳转成员列表
        goTeamUser(item){
            this.$router.push({
                path: '/teamUserList',
                query: {
                    id: item.id
                }
            })
        },
        getSysLabelValueList() {
            let that = this
            if (!localStorage.getItem("oa_token")) {
                return;
            }
            let params = {
                sysLabelId: 1,
            };
            API.getSysLabelValueList(params).then(res => {
                if (res.key === 1000) {
                    var list = res.result.list
                    var resultList = []
                    if(localStorage.getItem("oa_usermsg") && JSON.parse(localStorage.getItem("oa_usermsg")).userType == 1){
                        resultList.push(
                            {
                                id: 0,
                                sysValueName: '负责人'
                            }
                        )
                    }
                    this.list = resultList.concat(list)
                } else {
                    this.$message({
                        type: "warning",
                        message: res.message
                    });
                }
            });
        }
    },
}
</script>
<style scoped>
.show-item {
    padding-top: 16pt;
    width: 90%;
    margin: 1px auto;
}

.team-box-card {
    border-radius: 50pt;
    border: 1pt solid white;
    color: white;
    width: 50%;
    display: inline-block;
}
.team-box-card:hover {
    color: #FBC600;
    border-color: #FBC600;
    cursor: pointer;
}

.clearfix:before,
.clearfix:after {
    display: table;
    content: "";
}

.clearfix:after {
    clear: both
}

.card-show-color {
    list-style-type: circle;
    height: 10px;
    width: 10px;
}

.show-card-item {
    width: 100%;
    height: 30pt;
    margin: 0pt auto;
}
.show-card-item-text{
    text-align: center;
    display: flex;
}
.show-card-item-left {
    font-size: 15px;
    font-weight: bold;
    width: 40%;
    text-align: left;
}

.show-card-item-right {
    text-align: left;
    font-size: 15pt;
    font-weight: bold
}

.show-card-button {
    display: inline-flex;
    margin-top: 10px;
}

.show-card-item-title {
    font-size: 20px;
    font-weight: bold;
    height: 15pt;
}

.scroll-list {
    height: calc(100vh);
    overflow: auto;
}
.back{
    height: 20pt;
    text-align: left;
    margin-left: 20pt;
    margin-top: -20pt;
}
.back img{
    height: 20pt;
    width: 20pt;
    cursor: pointer;
}

</style>