<template>
    <div>
        <div class="show-item">
            <el-card class="box-card" shadow="always">
                <div class="show-card-item">
                    <div class="show-card-item-left">项目名称</div>
                    <div class="show-card-item-right">{{ item.projectName }}</div>
                </div>
                <div class="show-card-item">
                    <div class="show-card-item-left">集团</div>
                    <div class="show-card-item-right">{{ item.groupName }}</div>
                </div>
                <div class="show-card-item">
                    <div class="show-card-item-left">来源类型</div>
                    <div class="show-card-item-right">{{ item.sourceTypeName }}</div>
                </div>
                <div class="show-card-item">
                    <div class="show-card-item-left">项目类型</div>
                    <div class="show-card-item-right">{{ item.projectTypeName }}</div>
                </div>
                <div class="show-card-item">
                    <div class="show-card-item-left">创建时间</div>
                    <div class="show-card-item-right">{{ item.createTimeString }}</div>
                </div>
                <div class="show-card-item">
                    <div class="show-card-item-left">是否提案&比稿</div>
                    <div class="show-card-item-right">{{ item.haveProposal == 1 ? '是' : '否' }}</div>
                </div>
                <div class="show-card-item">
                    <div class="show-card-item-left">立项时间</div>
                    <div class="show-card-item-right">{{ item.projectApprovalTimeString }}</div>
                </div>
                <div class="show-card-item">
                    <div class="show-card-item-left">提案或执行要求概要</div>
                    <div class="show-card-item-right">{{ item.summaryOfRequirements }}</div>
                </div>
                <div class="show-card-item">
                    <div class="show-card-item-left">项目资料</div>
                    <div class="show-card-item-right">{{ item.projectInfo }}</div>
                </div>
                <div class="show-card-item">
                    <div class="show-card-item-left">关联客户</div>
                    <div class="show-card-item-right">{{ item.clientsLinkNamesString }}</div>
                </div>
                <div class="show-card-item">
                    <div class="show-card-item-left">项目创建者</div>
                    <div class="show-card-item-right">{{ item.createUserName }}</div>
                </div>
                <div class="show-card-item">
                    <div class="show-card-item-left">项目负责人</div>
                    <div class="show-card-item-right">{{ item.leaderUserName }}</div>
                </div>
                <div class="show-card-item">
                    <div class="show-card-item-left">团队成员</div>
                    <div class="show-card-item-right">{{ item.teamUserNames }}</div>
                </div>
                <div class="show-card-button" v-if="!viewUserId">
                    <el-button type="primary" round @click="doUpdate()" v-if="item.createUserId == userMsg.id">修改</el-button>
                </div>

                <!-- <div class="show-card-item">
                    <div class="show-card-item-view-more" v-if="!showMore" @click="doViewShowMore"><i
                            class="el-icon-caret-bottom"></i> 查看更多</div>
                    <div class="show-card-item-view-more" v-if="showMore" @click="doViewShowMore"><i
                            class="el-icon-caret-top"></i> 隐藏</div>
                </div> -->

            </el-card>
        </div>
        
    </div>
</template>
<script>
import API from "@/api/api_index";
export default {
    name: 'project_msg',
    props: ["item", "viewUserId", "updateProjectStatus", "updateProjectMsg"],
    data() {
        return {
            version: 1,
            reason: null,
            dialogVisible: false,
            reasonType: 1, //1、评估拒绝
            fileDialogVisible: false,
            dataToken: {
                token: localStorage.getItem("oa_token")
            },
            userMsg: {},
            showMore: false,
        }
    },
    created() { },
    mounted() {
        let userMsg = localStorage.getItem("oa_usermsg")
        this.userMsg = JSON.parse(userMsg)
    },
    methods: {
        doUpdate(){
            this.$emit('doUpdateItem', this.item)
        },
        doViewShowMore() {
            this.showMore = !this.showMore
        },
    },
}
</script>
<style>
.el-card__body,
.el-main {
    color: white;
    margin: 10pt auto;
    width: 90%;
}

.el-button--primary {
    background-color: #FBC600;
    border-color: #FBC600;
    color: black;
}
</style>
<style scoped>
.show-item {
    padding-top: 10px;
    width: 90%;
    margin: 1px auto;
}

.box-card {
    border-radius: 10pt;
}

.clearfix:before,
.clearfix:after {
    display: table;
    content: "";
}

.clearfix:after {
    clear: both
}

.card-show-color {
    list-style-type: circle;
    height: 10px;
    width: 10px;
}

.show-card-item {
    display: inline-flex;
    width: 100%;
    margin-top: 10px;
}

.show-card-item-left {
    font-size: 15px;
    font-weight: bold;
    width: 40%;
    text-align: right;
}

.show-card-item-right {
    width: 60%;
    text-align: left;
    padding-left: 10pt;
}

.show-card-button {
    display: inline-flex;
    margin-top: 10px;
    padding-top: 10pt;
}

.show-card-item-view-more {
    width: 100%;
    text-align: center;
    cursor: pointer;
    margin-top: 10pt;
}
</style>