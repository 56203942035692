<template>
    <div style="margin-top: -40px;">
        <div class="back">
            <div>
                <img alt="" :src="require('@/assets/image/返回.png')" @click="goBack" />
            </div>
        </div>
        <div class="scroll-list" v-infinite-scroll="load" infinite-scroll-immediate="disabled"
            v-if="list && list.length > 0">
            <div v-for="(item, index) in list" :key="index">
                <div class="show-item" @click="goUserIndex(item)">
                    <el-card class="box-card" shadow="always">
                        <div class="show-item-left">
                            <div class="show-item-head">
                                <el-avatar size="large" :src="item.avatar"></el-avatar>
                            </div>
                            <div>
                                <div class="show-item-name">
                                    {{ item.nickName }}
                                </div>
                            </div>
                            <div class="show_item_position">
                                {{ item.position ? item.position : '--' }}
                                <div style="padding-top: 5pt;">
                                    {{ item.department ? item.department : '--' }}
                                </div>
                            </div>
                        </div>
                    </el-card>
                </div>
            </div>
            <p v-if="loading" style="color: white;padding-top: 20px;">加载中...</p>
            <div v-if="allPageNumber < pageNum">
                <p style="color: white;padding-top: 20px;">暂无数据</p>
            </div>
            <div v-if="noMoreLoading">
                <el-empty image="https://oa.mejour.cn/logo/LOGO@2x.png" description="暂无数据"></el-empty>
            </div>
            <div style="height: 40px;"></div>
        </div>
    </div>
</template>
<script>
import API from "@/api/api_index";
export default {
    name: 'team_user_list',
    props: {},
    components: {
    },
    data() {
        return {
            version: 1,
            startTime: new Date(new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate() + ' 00:00:00'),
            endTime: new Date(new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate() + ' 23:59:59'),
            list: [],
            statusType: 2,
            statusIndex: 0,
            pageNum: 1,
            pageSize: 15,
            loading: false,
            noMoreLoading: false,
            teamId: null,
            count: null,
            allPageNumber: 1,
        }
    },
    computed: {
        noMore() {
            return this.list >= this.count
        },
        disabled() {
            return this.loading || this.noMore
        }
    },
    created() {
        this.teamId = this.$route.query.id
        this.getUserTeamList()
    },
    mounted() { },
    methods: {
        goBack() {
            if (this.$router.history._startLocation == this.$route.fullPath) {
                this.$router.push({
                    path: '/',
                    query: {}
                })
                location.reload()
            } else {
                this.$router.back(-1)
            }
        },
        goUserIndex(item){
            let userMsg = {
                avatar: item.avatar,
                gender: item.gender,
                mobile: item.mobile,
                userType: item.userType,
                username: item.nickName,
            }
            localStorage.setItem("oa_viewUserMsg", JSON.stringify(userMsg))
            this.$router.push({
                path: '/',
                query: {
                    viewUserId: item.id
                }
            })
        },
        load() {
            this.loading = true
            setTimeout(()=> {
                this.pageNum += 1
                this.getUserTeamList()
            },1000)
        },
        getUserTeamList() {
            if(this.allPageNumber < this.pageNum){
                this.loading = false
                return
            }
            if (!localStorage.getItem("oa_token")) {
                return;
            }
            let params = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
            };
            if(this.teamId){
                params.jobUserType = this.teamId * 1
            }
            API.getUserTeamList(params).then(res => {
                if (res.key === 1000) {
                    this.count = res.result.count
                    this.allPageNumber = res.result.allPageNumber
                    var list = res.result.list
                    list.forEach((item, index) => {
                    })
                    this.list = this.list.concat(list)
                    if (this.list.length == 0) {
                        this.noMoreLoading = true
                    }
                    this.loading = false
                } else {
                    this.$message({
                        type: "warning",
                        message: res.message
                    });
                }
            });
        }
    },
}
</script>
<style scoped>
.show-item {
    padding-top: 10px;
    width: 90%;
    margin: 1px auto;
    cursor: pointer;
}
.show-item-left{
    display: flex;
}
.box-card {
}

.clearfix:before,
.clearfix:after {
    display: table;
    content: "";
}

.clearfix:after {
    clear: both
}

.card-show-color {
    list-style-type: circle;
    height: 10px;
    width: 10px;
}

.scroll-list {
    height: calc(100vh);
    overflow: auto;
}

.show-item-head{
    width: 10%;
}
.show-item-name{
    font-size: 20px;
    padding-left: 20pt;
    margin: 10pt auto;
}
.show_item_position{
    padding-left: 20pt;
    margin: 10pt auto;
}

.back{
    height: 20pt;
    text-align: left;
    margin-left: 20pt;
    margin-top: -20pt;
}
.back img{
    height: 20pt;
    width: 20pt;
    cursor: pointer;
}

</style>