<template>
    <div>
        <div class="show-item">
            <el-card class="box-card" shadow="always">
                <div class="card-show-color" :style="'background-color: ' + item.color + ';'"></div>
                <div class="show-card-item">
                    <div class="show-card-item-left">任务</div>
                    <div class="show-card-item-right">{{ item.title }}</div>
                </div>
                <div class="show-card-item">
                    <div class="show-card-item-left">任务重要程度</div>
                    <div class="show-card-item-right">{{ item.taskImportance == 1 ? '特别紧急' : item.taskImportance == 2 ? '紧急' : item.taskImportance == 3 ? '较重' : '一般' }}</div>
                </div>
                <div class="show-card-item">
                    <div class="show-card-item-left">任务类型</div>
                    <div class="show-card-item-right">{{ item.taskCreateType == 1 ? '任务' : '会议' }}</div>
                </div>
                <div class="show-card-item">
                    <div class="show-card-item-left">项目阶段</div>
                    <div class="show-card-item-right">{{ item.taskTypeName }}</div>
                </div>
                <div class="show-card-item">
                    <div class="show-card-item-left">下单时间</div>
                    <div class="show-card-item-right">{{ item.start }}</div>
                </div>
                <div class="show-card-item">
                    <div class="show-card-item-left">提交时间</div>
                    <div class="show-card-item-right">{{ item.end }}</div>
                </div>
                <div class="show-card-item">
                    <div class="show-card-item-left">任务状态</div>
                    <div class="show-card-item-right">{{ item.status == 1 ? '评估中' : item.status == 2 ? '评估后拒接任务' : item.status == 3 ? '已接收任务，处理中' : item.status == 4 ? '验收中' : item.status == 5 ? '验收失败' : item.status == 6 ? '验收成功' : item.status == 11 ? '已删除' : '处理中' }}</div>
                </div>
                <div class="show-card-item" v-if="showMore">
                    <div class="show-card-item-left">背景</div>
                    <div class="show-card-item-right">{{ item.taskBackground }}</div>
                </div>
                <div class="show-card-item" v-if="showMore">
                    <div class="show-card-item-left">项目资料</div>
                    <div class="show-card-item-right">{{ item.taskInfo }}</div>
                </div>
                <div class="show-card-item" v-if="showMore">
                    <div class="show-card-item-left">目标</div>
                    <div class="show-card-item-right">{{ item.taskTarget }}</div>
                </div>
                <div class="show-card-item" v-if="showMore">
                    <div class="show-card-item-left">策略</div>
                    <div class="show-card-item-right">{{ item.taskStrategy }}</div>
                </div>
                <div class="show-card-item" v-if="showMore">
                    <div class="show-card-item-left">沟通点</div>
                    <div class="show-card-item-right">{{ item.taskBigIdea }}</div>
                </div>
                <div class="show-card-item" v-if="showMore">
                    <div class="show-card-item-left">核心玩法</div>
                    <div class="show-card-item-right">{{ item.taskCreative }}</div>
                </div>
                <div class="show-card-item" v-if="showMore">
                    <div class="show-card-item-left">创作形式</div>
                    <div class="show-card-item-right">{{ item.taskCreativeForm }}</div>
                </div>
                <div class="show-card-item" v-if="showMore">
                    <div class="show-card-item-left">相关参考</div>
                    <div class="show-card-item-right">{{ item.taskRef }}</div>
                </div>
                <div class="show-card-item" v-if="showMore">
                    <div class="show-card-item-left">投放渠道</div>
                    <div class="show-card-item-right">{{ item.taskMediaChannelString }}</div>
                </div>
                <div class="show-card-item" v-if="showMore">
                    <div class="show-card-item-left">投放周期</div>
                    <div class="show-card-item-right">{{ item.taskTimeLineString }}</div>
                </div>
                <div class="show-card-item" v-if="showMore">
                    <div class="show-card-item-left">其他补充说明</div>
                    <div class="show-card-item-right">{{ item.taskOther }}</div>
                </div>
                <div class="show-card-item" v-if="showMore">
                    <div class="show-card-item-left">创建者</div>
                    <div class="show-card-item-right">{{ item.createUserName }}</div>
                </div>
                <div class="show-card-item" v-if="showMore">
                    <div class="show-card-item-left">执行者</div>
                    <!-- <div class="show-card-item-right">{{ item.performerUserName }}</div> -->
                    <div class="show-card-item-right">
                        <div v-for="(performItem, performIndex) in item.performUserList" :key="performIndex" style="margin: 10pt auto;display: flex;">
                            <div class="avatar">
                                <el-avatar size="medium" :src="performItem.userPic"></el-avatar>
                            </div>
                            <div class="user-text" style="display: inline-flex;padding-top: 12pt;padding-left: 5pt;">
                                <div class="user-text-username" style="font-size: 10px;">{{ performItem.username }}</div>
                            </div>
                            <div class="user-text" style="display: inline-flex;padding-top: 12pt;padding-left: 5pt;">
                                <div class="user-text-username" :style="'font-size: 10px;color: ' + (performItem.readStatus && performItem.readStatus == 1 ? 'red' : 'green')">{{ performItem.readStatus && performItem.readStatus == 1 ? '未读' : '已读' }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="show-card-item" v-if="showMore">
                    <div class="show-card-item-left">参与者</div>
                    <!-- <div class="show-card-item-right">{{ item.joinUserName }}</div> -->
                    <div class="show-card-item-right">
                        <div v-for="(joinItem, joinIndex) in item.joinUserList" :key="joinIndex" style="margin: 10pt auto;display: flex;">
                            <div class="avatar">
                                <el-avatar size="medium" :src="joinItem.userPic"></el-avatar>
                            </div>
                            <div class="user-text" style="display: inline-flex;padding-top: 12pt;padding-left: 5pt;">
                                <div class="user-text-username" style="font-size: 10px;">{{ joinItem.username }}</div>
                            </div>
                            <div class="user-text" style="display: inline-flex;padding-top: 12pt;padding-left: 5pt;">
                                <div class="user-text-username" :style="'font-size: 10px;color: ' + (joinItem.readStatus && joinItem.readStatus == 1 ? 'red' : 'green')">{{ joinItem.readStatus && joinItem.readStatus == 1 ? '未读' : '已读' }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="show-card-item" v-if="item.taskAssessFailReason && showMore">
                    <div class="show-card-item-left">任务评估拒绝理由</div>
                    <div class="show-card-item-right">{{ item.taskAssessFailReason }}</div>
                </div>
                <div class="show-card-item" v-if="item.taskCheckFailReason && showMore">
                    <div class="show-card-item-left">任务审核失败原因</div>
                    <div class="show-card-item-right">{{ item.taskCheckFailReason }}</div>
                </div>
                <div class="show-card-item" v-if="item.finish && showMore">
                    <div class="show-card-item-left">最终完成时间</div>
                    <div class="show-card-item-right">{{ item.finish }}</div>
                </div>
                <div class="show-card-button" v-if="!viewUserId && item.performerUsers && item.performerUsers.indexOf(userMsg.id) > -1">
                    <el-button type="primary" round @click="doUpdateTaskStatus(3)" v-if="item.status == 1 && !item.checkReadTaskStatus">接受</el-button>
                    <!-- <el-button type="primary" round @click="doRefuse(1)" v-if="item.status == 1">拒绝</el-button> -->
                    <!-- <el-button type="primary" round @click="doUpdateFile()" v-if="item.status == 3">提交文件</el-button> -->
                    <el-button type="primary" round @click="doSubmitTask()" v-if="item.status == 3 || item.status == 5">提交</el-button>
                </div>
                <div class="show-card-button" v-if="!viewUserId && item.joinUsers && item.joinUsers.indexOf(userMsg.id) > -1">
                    <el-button type="primary" round @click="doUpdateTaskStatus(3)" v-if="item.status == 1 && !item.checkReadTaskStatus">确定</el-button>
                </div>
                <div class="show-card-button" v-if="!viewUserId && userMsg.id == item.createUserId" style="padding-left: 5pt;">
                    <el-button type="primary" round @click="doCheckTask(6)" v-if="item.status == 4">审核通过</el-button>
                    <el-button type="primary" round @click="doRefuse(2)" v-if="item.status == 4">拒绝</el-button>
                </div>
                <div class="show-card-button" v-if="!viewUserId && userMsg.id == item.createUserId && (!showType || showType != 2)" style="padding-left: 5pt;">
                    <el-button type="primary" round @click="doUpdateTask()">修改</el-button>
                </div>
                <div class="show-card-button" v-if="!viewUserId && userMsg.id == item.createUserId && (!item.createWebType || item.createWebType != 2)" style="padding-left: 5pt;">
                    <el-button type="primary" round @click="doDelTask()">删除</el-button>
                </div>

                <div class="show-card-item">
                    <div class="show-card-item-view-more" v-if="!showMore" @click="doViewShowMore"><i class="el-icon-caret-bottom"></i> 查看更多</div>
                    <div class="show-card-item-view-more" v-if="showMore" @click="doViewShowMore"><i class="el-icon-caret-top"></i> 隐藏</div>
                </div>

            </el-card>
        </div>
        <el-dialog
            title="提交文件"
            :visible.sync="fileDialogVisible"
            width="100%"
            :before-close="handleCloseFileDialog">
            <div>
                <el-upload
                    class="upload-demo"
                    drag
                    action="https://oa.mejour.cn/oa/app/user/getTaskFileMediaId/"
                    :data="dataToken"
                    multiple>
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                    <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
                </el-upload>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button round @click="fileDialogVisible = false">取 消</el-button>
                <el-button round type="primary" @click="doAddFile">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog
            title="理由"
            :visible.sync="dialogVisible"
            width="80%"
            :before-close="handleClose">
            <div>
                <el-input type="textarea" :rows="4" placeholder="请输入理由" v-model="reason"></el-input>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button round @click="dialogVisible = false">取 消</el-button>
                <el-button round type="primary" @click="doAddRefuse">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import API from "@/api/api_index";
export default {
    name: 'task_msg',
    props: ["item","viewUserId","showType"],//showType为2是列表页面进入的
    data() {
        return {
            version: 1,
            reason: null,
            dialogVisible: false,
            reasonType: 1, //1、评估拒绝
            fileDialogVisible: false,
            dataToken: {
                token: localStorage.getItem("oa_token")
            },
            userMsg: {},
            showMore: false,
        }
    },
    created() {},
    mounted() {
        let userMsg = localStorage.getItem("oa_usermsg")
        this.userMsg = JSON.parse(userMsg)
        this.showMore = false
    },
    methods: {
        doUpdateTask(){
            this.$emit("doUpdateTask",this.item.id)
        },
        doViewShowMore(){
            this.showMore = !this.showMore
        },
        doCheckTask(status) {
            let that = this
            if (!localStorage.getItem("oa_token")) {
                return;
            }
            let params = {
                taskId: that.item.id,
                status: status,
            };
            if (that.reason) {
                params.reason = that.reason
            }
            API.checkFileTask(params).then(res => {
                if (res.key === 1000) {
                    this.$message({
                        type: "success",
                        message: "提交成功"
                    });
                    that.item.status = status
                    if (status == 5) {
                        that.item.taskCheckFailReason = that.reason
                    }
                    that.reason = null
                } else {
                    this.$message({
                        type: "warning",
                        message: res.message
                    });
                }
            });
        },
        doAddFile(){
            this.fileDialogVisible = false
        },
        doSubmitTask(){
            let that = this
            if (!localStorage.getItem("oa_token")) {
                return;
            }
            let params = {
                taskId: that.item.id,
                mediaId: "3_g9I9h56vmEovDzjEDopBjleqpCUi8vOJ89FuhY5JQaSTOOBsuHHAMRuDcPQ67oa"
            };
            API.sendTaskFile(params).then(res => {
                if (res.key === 1000) {
                    this.$message({
                        type: "success",
                        message: "提交成功"
                    });
                    that.item.status = 4
                } else {
                    this.$message({
                        type: "warning",
                        message: res.message
                    });
                }
            });
        },
        doUpdateFile(){
            this.fileDialogVisible = true
        },
        handleCloseFileDialog(){
            this.fileDialogVisible = false
        },
        doAddRefuse(){
            this.dialogVisible = false
            if(this.reasonType == 1){
                this.doUpdateTaskStatus(2)
            }else if(this.reasonType == 2){
                this.doCheckTask(5)
            }
        },
        doRefuse(type){
            this.reasonType = type
            this.dialogVisible = true
        },
        handleClose(){
            this.dialogVisible = false
        },
        doUpdateTaskStatus(status){
            let that = this
            if (!localStorage.getItem("oa_token")) {
                return;
            }
            let params = {
                taskId: that.item.id,
                status: status,
            };
            if(that.reason){
                params.reason = that.reason
            }
            API.assessTask(params).then(res => {
                if (res.key === 1000) {
                    this.$message({
                        type: "success",
                        message: "提交成功"
                    });
                    that.item.status = status
                    if(status == 2){
                        that.item.taskAssessFailReason = that.reason
                    }
                    that.reason = null
                    this.$emit('delItem')
                } else {
                    this.$message({
                        type: "warning",
                        message: res.message
                    });
                }
            });
        },
        doDelTask() {
            let that = this
            if (!localStorage.getItem("oa_token")) {
                return;
            }
            let params = {
                taskId: that.item.id,
            };
            API.delTask(params).then(res => {
                if (res.key === 1000) {
                    this.$message({
                        type: "success",
                        message: "提交成功"
                    });
                    that.item.status = 11
                    that.reason = null
                    this.$emit('delItem')
                } else {
                    this.$message({
                        type: "warning",
                        message: res.message
                    });
                }
            });
        },
    },
}
</script>
<style>
.el-card__body, .el-main{
    color: white;
    margin: 10pt auto;
    width: 90%;
}
.el-button--primary{
    background-color: #FBC600;
    border-color: #FBC600;
    color: black;
}
</style>
<style scoped>
.show-item {
    padding-top: 10px;
    width: 90%;
    margin: 1px auto;
}

.box-card {
    border-radius: 10pt;
}

.clearfix:before,
.clearfix:after {
    display: table;
    content: "";
}

.clearfix:after {
    clear: both
}

.card-show-color {
    list-style-type: circle;
    height: 10px;
    width: 10px;
}

.show-card-item {
    display: inline-flex;
    width: 100%;
    margin-top: 10px;
}

.show-card-item-left {
    font-size: 15px;
    font-weight: bold;
    width: 40%;
    text-align: right;
}

.show-card-item-right {
    width: 60%;
    text-align: left;
    padding-left: 10pt;
}
.show-card-button{
    display: inline-flex;
    margin-top: 10px;
    padding-top: 10pt;
}
.show-card-item-view-more{
    width: 100%;
    text-align: center;
    cursor: pointer;
    margin-top: 10pt;
}
</style>