import axios from "axios";
import { bus } from "@/assets/js/bus.js";
import Qs from "qs";
import { Message } from "element-ui";

// 时限本地缓存
// import Storage from "jss/ls";
const ls = localStorage;

axios.defaults.withCredentials = true;
// 解决axios传参后台接收不到问题    https://blog.csdn.net/csdn_yudong/article/details/79668655
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN
// axios.defaults.headers.post["Content-Type"] =
//     "application/x-www-form-urlencoded;charset=UTF-8"; //配置请求头
axios.defaults.headers.post["Content-Type"] =
    "application/json;charset=UTF-8"; //配置请求头
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
//添加一个请求拦截器
// axios.interceptors.request.use(function (config) {
//   console.dir(config)
//   return config
// }, function (error) {
//   // Do something with request error
//   return Promise.reject(error)
// })

// 添加一个响应拦截器
axios.interceptors.response.use(
    function (response) {
        if (response.data && response.data.key) {
            if (parseInt(response.data.key) !== 1000) {
                Message({
                    type: "warning",
                    message: response.data.message,
                    showClose: true,
                    duration: 2000
                });
                bus.$emit("show-loading", false); // 暂时注释
            }
            if (parseInt(response.data.key) === 1001) {
                ls.removeItem("oa_usermsg");
                ls.removeItem("oa_token");
                location.replace(location.origin)
            }
        }
        return response;
    },
    function (error) {
        // Do something with response error
        return Promise.reject(error);
    }
);

//基地址
let base = ""; //接口代理地址参见：config/index.js中的proxyTable配置

//通用方法
export const POST = (url, params) => {
    return axios
        .post(`${base}${url}?token=` + ls.getItem("oa_token"), JSON.stringify(params))
        .then(res => res.data);
};


export const POSTQS = (url, params) => {
    return axios
        .post(`${base}${url}?token=` + ls.getItem("oa_token"), Qs.stringify(params))
        .then(res => res.data);
};

export const POSTFile = (url, params, config) => {
    return axios.post(`${base}${url}`, params, config).then(res => res.data);
};

export const GET = (url, params) => {
    return axios.get(`${base}${url}`, { params: params }).then(res => res.data);
};

export const PUT = (url, params) => {
    return axios.put(`${base}${url}`, params).then(res => res.data);
};

export const DELETE = (url, params) => {
    return axios
        .delete(`${base}${url}`, { params: params })
        .then(res => res.data);
};

export const PATCH = (url, params) => {
    return axios.patch(`${base}${url}`, params).then(res => res.data);
};
