<template>
    <div v-loading="showLoading">
        <div class="back" v-if="viewUserId">
            <div>
                <img alt="" :src="require('@/assets/image/返回.png')" @click="goBack" />
            </div>
        </div>
        <div class="show-user" :style="viewUserId ? 'margin: 0pt auto' : ''">
            <el-card class="box-card" shadow="always" style="width: 100%">
                <div class="box-card-left">
                    <!-- 个人信息展示 -->
                    <div class="avatar">
                        <el-avatar size="large" :src="userMsg.avatar"></el-avatar>
                    </div>
                    <div class="user-text">
                        <div class="user-text-username">{{ userMsg.username }}</div>
                        <!-- <div class="user-text-sex">{{ userMsg.gender && userMsg.gender == 1 ? '男' : '女' }}</div> -->
                        <div class="user-text-dep">{{ userMsg.position }}  <div class="user-text-tel">{{ userMsg.mobile }}</div></div>
                    </div>
                </div>
                <div class="box-card-right">
                    <img class="box-card-right-img" :src="require('@/assets/image/LOGO@2x.png')" alt="" />
                </div>
            </el-card>
            <el-card class="box-card" id="box-button" shadow="always" v-if="!viewUserId">
                <div class="person-button">
                    <div class="person-button-item">
                        <img class="person-button-item-img" :src="require('@/assets/image/任务@2x.png')" alt="" @click="goTaskList(2)" />
                    </div>
                    <div class="person-button-item">
                        <el-badge :value="timeLimitNum" :max="99" class="person-button-item-badge" :hidden="!timeLimitNum || timeLimitNum == 0">
                            <img class="person-button-item-img" :src="require('@/assets/image/提醒@2x.png')" alt="" @click="goNoticeList()" />
                        </el-badge>
                    </div>
                    <div class="person-button-item" v-if="userMsg.userType && (userMsg.userType == 1 || userMsg.userType == 2)">
                        <img class="person-button-item-img" :src="require('@/assets/image/项目团队@2x.png')" alt="" @click="goTeamList()" />
                    </div>
                    <div class="person-button-item">
                        <el-badge :value="estimateTaskNum" :max="99" class="person-button-item-badge" :hidden="!estimateTaskNum || estimateTaskNum == 0">
                            <img class="person-button-item-img" :src="require('@/assets/image/通知@2x.png')" alt="" @click="goTaskList(1)" />
                        </el-badge>
                    </div>
                </div>
            </el-card>
        </div>
        <div class="calendar">
            <div class="calendar-title">
                <div class="calendar-title-image">
                    <div class="calendar-title-image-left">
                        <img :src="require('@/assets/image/日历@2x.png')" alt="" />
                    </div>
                    <div class="calendar-title-image-right" v-if="userMsg.userType && (userMsg.userType == 1 || userMsg.userType == 2)">
                        <img :src="require('@/assets/image/矢量智能对象@2x.png')" alt="" @click="viewProjectList" />
                    </div>
                </div>
            </div>
            <FullCalendarModel :type="1" :fullShowNum="fullShowNum" :fullShowList="fullShowList" :holidayList="holidayList" :holidayChangeVersion="holidayChangeVersion"
            @fullCalendarTime="fullCalendarTimeGet" @chooseEventClick="chooseEventClick" @fullCalendarTimeUpdate="fullCalendarTimeUpdate"></FullCalendarModel>
        </div>
        <div style="height: 20px;"></div>

        <div class="font" v-if="!viewUserId">
            <div class="font-botton">
                <div class="font-botton-choose" v-if="(showChooseStatus && powerList && powerList.length > 1) || (powerList && powerList.length == 1 && powerList.indexOf('user:addProject') > -1)" style="margin-top: -40pt;">
                    <img @click="hoverProBtnClick" :src="require('@/assets/image/新建项目@2x.png')" alt="">
                </div>
                <div class="font-botton-choose" v-if="(showChooseStatus && powerList && powerList.length > 1) || (powerList && powerList.length == 1 && powerList.indexOf('user:addTask') > -1)">
                    <img @click="hoverTaskBtnClick" :src="require('@/assets/image/新建任务@2x.png')" alt="">
                </div>
                <div class="font-botton-center" ref="box" v-if="powerList && powerList.length > 1" :style="showChooseStatus ? 'display: none' : ''">
                    <img @click="showChooseStatus = !showChooseStatus" :src="require('@/assets/image/新建@2x.png')" alt="">
                </div>
            </div>
        </div>

        <div v-if="!viewUserId">
            <!-- <j-hover-btn bgColor = 'pink'
                v-if="userMsg.userType && (userMsg.userType == 1 || userMsg.userType == 2)"
                :width = "60"
                text = '添加项目' 
                :btn-style = "proBtnStyle" 
                @hoverBtnClick = "hoverProBtnClick()">
            </j-hover-btn>
            <j-hover-btn bgColor = 'pink' 
                v-if="userMsg.userType && (userMsg.userType == 1 || userMsg.userType == 2)"
                :width = "60"
                text = '添加任务' 
                :btn-style = "btnStyle" 
                @hoverBtnClick = "hoverTaskBtnClick()">
            </j-hover-btn> -->
            <!-- <j-hover-btn bgColor = 'pink' 
                :width = "60"
                text = '退出登录' 
                :btn-style = "logoutbtnStyle" 
                @hoverBtnClick = "hoverLogoutBtnClick()">
            </j-hover-btn> -->
        </div>
        <div class="fontClass"></div>
        <el-drawer
            title="PROJECT"
            size="90%"
            class="drawer-project"
            :style="isMobile ? '' : 'left: 26%'"
            :visible.sync="drawerProject"
            :direction="directionProject">
            <div class="xin-main">
                <div class="drawer-project-item">
                    <div class="drawer-project-item-title">项目来源</div>
                </div>
                <div class="drawer-project-item-divider"></div>
                <div class="drawer-project-item">
                    <div class="drawer-project-item-name">集团 GROUP </div>
                    <el-select size="medium" v-model="groupId" placeholder="" @visible-change="visibleChangeGroup">
                        <el-option
                            v-for="item in projectGroupOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div class="drawer-project-item">
                    <div class="drawer-project-item-name">来源类型 ACCOUNT SOURCE TYPE </div>
                    <el-select v-model="projectSourceType" placeholder="" @visible-change="visibleChangeSourceType">
                        <el-option
                            v-for="item in projectSourceTypeOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div class="drawer-project-item">
                    <div class="drawer-project-item-name">项目类型 TYPE </div>
                    <el-select v-model="projectType" @visible-change="visibleChangeProjectType" placeholder="">
                        <el-option
                            v-for="item in projectTypeOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div class="drawer-project-item">
                    <div class="drawer-project-item-name">项目名称 PROJECT NAME </div>
                    <el-input v-model="projectName"></el-input>
                </div>
                <div class="drawer-project-item">
                    <div class="drawer-project-item-name">是否提案&比稿 HAVE PROPOSAL </div>
                    <el-radio-group v-model="haveProposal" text-color="#FABD00">
                        <el-radio 
                            v-for="item in haveProposalOptions"
                            :key="item.value"
                            :label="item.value"
                        >{{item.label}}</el-radio>
                    </el-radio-group>
                </div>
                <div class="drawer-project-item" style="display: block;">
                    <div class="drawer-project-item-name">立项时间 PROJECTAPPROVAL TIME </div>
                    <el-date-picker
                        @blur="visibleChangeProjectApprovalTime(2)"
                        @focus="visibleChangeProjectApprovalTime(1)"
                        v-model="projectApprovalTime"
                        :editable="false"
                        type="datetime">
                    </el-date-picker>
                </div>
                <div class="drawer-project-item" style="display: block;">
                    <div class="drawer-project-item-name">提案或执行要求概要 Summary of Requirements </div>
                    <el-input style="width: 90%;" type="textarea" :rows="4" v-model="summaryOfRequirements"></el-input>
                </div>
                <div class="drawer-project-item" style="display: block;">
                    <div class="drawer-project-item-name">项目资料 INFO </div>
                    <el-input style="width: 90%;" type="textarea" :rows="4" v-model="projectInfo"></el-input>
                </div>
                <div class="drawer-project-item">
                    <div class="drawer-project-item-name">关联客户 CLIENTS LINK </div>
                    <el-select v-model="clientsLinkIds" @visible-change="visibleChangeProjectClientsLink" multiple placeholder="">
                        <el-option
                            v-for="item in clientsLinkOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div class="drawer-project-item" style="padding-bottom: 15pt;padding-top: 15pt;">
                    <div class="drawer-project-item-user">
                        <div class="drawer-project-item-user-left" @click="goChooseLeader">
                            <img style="width: 26pt;height: 26pt;" :src="require('@/assets/image/oa-选择执行者.png')" />
                            <div class="drawer-project-item-user-name">项目负责人</div>
                        </div>
                    </div>
                    <div class="drawer-project-item-user">
                        <div class="drawer-project-item-user-right" @click="goChooseTeams">
                            <img style="width: 26pt;height: 26pt;" :src="require('@/assets/image/oa-选择参与者@3x.png')" />
                            <div class="drawer-project-item-user-name">项目团队</div>
                        </div>
                    </div>
                </div>
                <div class="drawer-project-item" v-if="leaderId && leaderName">
                    <div class="drawer-project-item-teams">
                        <div class="drawer-project-item-teams-title">项目负责人</div>
                        <div class="drawer-project-item-teams-content">
                            <div class="drawer-project-item-teams-content-name" v-for="(item,index) in [leaderName]" :key="index">
                                <div class="drawer-project-item-teams-content-name-show">{{ item }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="drawer-project-item" v-if="projectTeamsUsers && projectTeamsUsers.length > 0">
                    <div class="drawer-project-item-teams">
                        <div class="drawer-project-item-teams-title">团队成员</div>
                        <div class="drawer-project-item-teams-content">
                            <div class="drawer-project-item-teams-content-name" v-for="(item,index) in projectTeamsUsers" :key="index">
                                <div class="drawer-project-item-teams-content-name-show" @click="delTeamsUser(index,item)">{{ item.label }} <i class="el-icon-delete"></i></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="xin-footer">
                    <el-button type="warning" @click="drawerProject = false">取消</el-button>
                    <el-button type="primary" @click="doAddProject">确定</el-button>
                </div>
            </div>
        </el-drawer>
        <el-drawer
            title="BRIEFING"
            size="90%"
            class="drawer-project"
            :style="isMobile ? '' : 'left: 26%'"
            :visible.sync="drawerTask"
            :direction="directionTask">
            <div class="xin-main">
                <div class="drawer-project-item">
                    <div class="drawer-project-item-title">项目基本资料</div>
                </div>
                <div class="drawer-project-item-divider"></div>
                <div class="drawer-project-item">
                    <div class="drawer-project-item-name">客户 ACCOUNT 
                    </div>
                    <el-select v-model="projectId" filterable placeholder="">
                        <el-option
                            v-for="item in projectOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div class="drawer-project-item">
                    <div class="drawer-project-item-name">项目阶段 PHASE </div>
                    <el-select v-model="taskType" placeholder="">
                        <el-option
                            v-for="item in taskTypeOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div class="drawer-project-item">
                    <div class="drawer-project-item-name">下单时间 TIME </div>
                    <el-date-picker
                        v-model="startTime"
                        :editable="false"
                        type="datetime">
                    </el-date-picker>
                </div>
                <div class="drawer-project-item">
                    <div class="drawer-project-item-name">提交时间 DEADLINE </div>
                    <el-date-picker
                        @blur="visibleChangeTaskEndTime(2)"
                        @focus="visibleChangeTaskEndTime(1)"
                        v-model="endTime"
                        :editable="false"
                        type="datetime">
                    </el-date-picker>
                </div>
                <div class="drawer-project-item">
                    <div class="drawer-project-item-name">任务名称 WORKING </div>
                    <el-input maxlength="100" v-model="taskName"></el-input>
                </div>
                <!-- <div class="drawer-project-item">
                    <div class="drawer-project-item-name">任务类型 TYPE </div>
                    <el-select v-model="taskCreateType" @visible-change="visibleChangeTaskCreateType" @change="changeCreateType" placeholder="">
                        <el-option
                            v-for="item in taskCreateTypeOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </div> -->
                <div class="drawer-project-item">
                    <div class="drawer-project-item-name">任务重要程度 IMPORTANCE </div>
                    <el-select v-model="taskImportance" @visible-change="visibleChangeTaskImportance"  placeholder="">
                        <el-option
                            v-for="item in taskImportanceOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div class="drawer-project-item" style="display: block;">
                    <div class="drawer-project-item-name">背景 BACKGROUND </div>
                    <el-input style="width: 90%;" type="textarea" :rows="4" v-model="taskBackground"></el-input>
                </div>
                <div class="drawer-project-item" style="display: block;">
                    <div class="drawer-project-item-name">项目资料 INFO </div>
                    <el-input style="width: 90%;" type="textarea" :rows="4" v-model="taskInfo"></el-input>
                </div>
                <div class="drawer-project-item">
                    <div class="drawer-project-item-name">展示颜色 SHOWCOLOR </div>
                    <el-select v-model="showColor" @visible-change="visibleChangeTaskShowColor" placeholder="">
                        <el-option
                            v-for="item in colorOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                
                <div class="drawer-project-item">
                    <div class="drawer-project-item-title">项目栏</div>
                </div>
                <div class="drawer-project-item-divider"></div>
                
                <div class="drawer-project-item" style="display: block;">
                    <div class="drawer-project-item-name">目标 TARGET </div>
                    <el-input style="width: 90%;" type="textarea" :rows="3" v-model="taskTarget" ></el-input>
                </div>
                <div class="drawer-project-item" style="display: block;">
                    <div class="drawer-project-item-name" style="display: flex;">策略 STRATEGY <div class="drawer-project-item-name-sub">（平台玩法或项目执行逻辑）</div> </div>
                    <el-input style="width: 90%;" type="textarea" :rows="3" v-model="taskStrategy" ></el-input>
                </div>
                <div class="drawer-project-item" style="display: block;">
                    <div class="drawer-project-item-name">沟通点 BIG IDEA </div>
                    <el-input style="width: 90%;" type="textarea" :rows="3" v-model="taskBigIdea" ></el-input>
                </div>
                <div class="drawer-project-item" style="display: block;">
                    <div class="drawer-project-item-name">核心玩法 CREATIVE </div>
                    <el-input style="width: 90%;" type="textarea" :rows="4" v-model="taskCreative" ></el-input>
                </div>
                <div class="drawer-project-item" style="display: block;">
                    <div class="drawer-project-item-name" style="display: flex;">创作形式 CREATIVE FORM <div class="drawer-project-item-name-sub">（交付物为 keynote/ppt/pic…）</div> </div>
                    <el-input style="width: 90%;" v-model="taskCreativeForm" ></el-input>
                </div>
                <div class="drawer-project-item" style="display: block;">
                    <div class="drawer-project-item-name">相关参考 REF. </div>
                    <el-input style="width: 90%;" type="textarea" :rows="4" v-model="taskRef" ></el-input>
                </div>
                
                <div class="drawer-project-item">
                    <div class="drawer-project-item-title">投放栏（选填）</div>
                </div>
                <div class="drawer-project-item-divider"></div>
                
                <div class="drawer-project-item">
                    <div class="drawer-project-item-name">投放渠道 MEDIA CHANNEL </div>
                    <el-select v-model="taskMediaChannel" @visible-change="visibleChangeTaskMediaChannel" placeholder="">
                        <el-option
                            v-for="item in taskMediaChannelOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div class="drawer-project-item">
                    <div class="drawer-project-item-name">投放周期 TIMELINE </div>
                    <el-select v-model="taskTimeLine"  placeholder="">
                        <el-option
                            v-for="item in taskTimeLineOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div class="drawer-project-item" style="display: block;">
                    <div class="drawer-project-item-name">其他补充说明 OTHERS </div>
                    <el-input style="width: 90%;" type="textarea" :rows="5" v-model="taskOther" ></el-input>
                </div>
                
                <div class="drawer-project-item">
                    <div class="drawer-project-item-name">添加执行人 </div>
                    <el-select v-model="performerId" @visible-change="visibleChangeTaskPerformerId" clearable multiple filterable  placeholder="">
                        <el-option
                            v-for="item in userOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div class="drawer-project-item">
                    <div class="drawer-project-item-name">添加参与人 </div>
                    <el-select v-model="joinUserList" @visible-change="visibleChangeTaskJoinUserId" clearable multiple filterable  placeholder="">
                        <el-option
                            v-for="item in userOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                            :disabled="performerId.indexOf(item.value) > -1">
                        </el-option>
                    </el-select>
                </div>
                <div class="drawer-project-item">
                    <div class="xin-footer">
                        <el-button type="warning" @click="drawerTask = false">取消</el-button>
                        <el-button type="primary" @click="doAddTask">确定</el-button>
                    </div>
                </div>
            </div>
        </el-drawer>
        <!-- 任务详情弹窗 -->
        <TaskMsgDialog :taskMsgDialogVisible="taskMsgDialogVisible" :viewUserId="viewUserId" :item="showTaskItem" @closeTaskMsgDialog="closeTaskMsgDialog" 
        @delTaskItem="delTaskItem" @doUpdateTask="doUpdateTask"></TaskMsgDialog>
        <!-- 选择负责人弹窗 -->
        <el-drawer
            title="选择负责人"
            size="80%"
            class="drawer-project"
            :style="isMobile ? '' : 'left: 26%'"
            :visible.sync="dialogVisibleChooseLeader"
            :before-close="handleCloseChooseLeader">
            <div>
                <el-select v-model="leaderId" placeholder="">
                    <el-option
                        v-for="item in leaderOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <div class="xin-footer">
                <el-button type="warning" @click="dialogVisibleChooseLeader = false">取消</el-button>
                <el-button type="primary" @click="doSureLeader">确定</el-button>
            </div>
        </el-drawer>
        <!-- 选择团队弹窗 -->
        <el-drawer
            title="项目团队"
            size="80%"
            class="drawer-teams"
            :style="isMobile ? '' : 'left: 26%'"
            :visible.sync="dialogVisibleChooseTeams"
            :before-close="handleCloseChooseTeams">
            <div style="height: 80%;overflow: auto;">
                <div class="drawer-team" v-if="!showTeamUser">
                    <el-checkbox :indeterminate="isIndeterminate" v-model="checkAllTeams" @change="handleCheckAllTeamsChange">全选</el-checkbox>
                    <div style="margin: 15pt 0;"></div>
                    <div class="drawer-teams-teams" v-for="(item, index) in teamsOptions" :key="index">
                        <!-- <el-checkbox-group name="drawer-checkbox-group"> -->
                            <el-checkbox :indeterminate="item.isAllChoose" v-model="item.isChoose" @change="handleCheckedTeamsChange" :label="item.jobUserTypeName" >{{ item.jobUserTypeName }}</el-checkbox>
                        <!-- </el-checkbox-group> -->
                        <div class="team-view" @click="viewTeamUsers(item,index)">查看团队</div>
                    </div>
                </div>
                <div class="drawer-team" style="height: 100%;" v-if="showTeamUser">
                    <div class="back">
                        <div>
                            <img alt="" :src="require('@/assets/image/返回.png')" @click="backTeams" />
                        </div>
                    </div>
                    <el-checkbox :indeterminate="isIndeterminateUsers" v-model="checkAllTeamUsers" @change="handleCheckAllTeamUsersChange">全选</el-checkbox>
                    <div style="margin: 15pt 0;"></div>
                    <div style="height: 80%;overflow: auto;">
                        <div class="drawer-teams-teams" v-for="(item, index) in teamUserList" :key="index">
                            <el-checkbox-group name="drawer-checkbox-group" v-model="item.isChoose" @change="handleCheckedTeamUsersChange">
                                <el-checkbox :label="item.nickName" >{{ item.nickName }}</el-checkbox>
                            </el-checkbox-group>
                        </div>
                    </div>
                </div>
                <!-- <el-table
                    ref="multipleTableTeams"
                    :data="allUserOptions"
                    tooltip-effect="dark"
                    :row-key="selRowKey"
                    style="width: 100%;overflow-y: scroll;height: 100%;background-color: #212121;color: #ede6da;left: 25%;"
                    @selection-change="handleSelectionChangeTeams"
                >
                    <el-table-column
                        type="selection"
                        reserve-selection
                        width="55">
                    </el-table-column>
                    <el-table-column
                        prop="label"
                        label="姓名"
                        width="120">
                    </el-table-column>
                </el-table> -->
            </div>
            <div class="xin-footer">
                <el-button type="warning" @click="showTeamUser = !showTeamUser" v-if="showTeamUser">取消</el-button>
                <el-button type="primary" @click="doSureTeamUsers" v-if="showTeamUser">确定</el-button>
                <el-button type="warning" @click="handleCloseChooseTeams" v-if="!showTeamUser">取消</el-button>
                <el-button type="primary" @click="doSureTeams" v-if="!showTeamUser">确定</el-button>
            </div>
        </el-drawer>
        <ProjectListDialog :projectListDialogVisible="projectListDialogVisible" :isMobile="isMobile" :viewUserId="viewUserId" 
        @changeProjectDialogVisible="changeProjectDialogVisible" @doUpdateProjectItem="doUpdateProjectItem" 
        :updateProjectStatus="updateProjectStatus" :updateProjectMsg="updateProjectMsg"></ProjectListDialog>
    
    </div>
</template>

<script>
import FullCalendarModel from '@/components/FullCalendarModel';
import TaskMsgDialog from '@/components/dialogs/TaskMsgDialog.vue';
import ProjectListDialog from '@/components/dialogs/ProjectListDialog.vue';
import API from "@/api/api_index";
// import WebSocket from '@/components/WebSocket';
import { connectWebsocket, closeWebsocket } from '@/util/websocket';
export default {
    name: 'home_index',
    props: {},
    components: {
        FullCalendarModel,
        TaskMsgDialog,
        ProjectListDialog,
        // WebSocket,
    },
    data() {
        return {
            isMobile: true,
            version: 1,
            userMsg: {},
            btnStyle: {
                "fontSize":'small',
                "top":'72vh',
                "left":'10vw'
            },
            proBtnStyle: {
                "fontSize":'small',
                "top":'60vh',
                "left":'10vw'
            },
            logoutbtnStyle: {
                "fontSize":'small',
                "top":'84vh',
                "left":'10vw'
            },
            addBtnStyle: {
                "fontSize": 'small',
                "top": '72vh',
                "left": '10vw',
                "background-image": require('@/assets/image/新建@2x.png'),
            },
            drawerProject: false,
            directionProject: 'rtl',
            colorOptions: [],
            drawerTask: false,
            projectId: null,
            taskName: null,
            taskType: null,
            taskBackground: null,
            taskInfo: null,
            taskTarget: null,
            taskStrategy: null,
            taskBigIdea: null,
            taskCreative: null,
            taskCreativeForm: null,
            taskRef: null,
            taskMediaChannel: null,
            taskTimeLine: null,
            taskOther: null,
            taskCreateType: 1,
            startTime: null,
            endTime: null,
            taskImportance: 4,
            performerId: [],
            showColor: null,
            taskImportanceOptions: [
                {
                    label: '特别紧急',
                    value: 1,
                },
                {
                    label: '紧急',
                    value: 2,
                },
                {
                    label: '较重',
                    value: 3,
                },
                {
                    label: '一般',
                    value: 4,
                },
            ],
            directionTask: 'rtl',
            projectOptions: [],
            taskTypeOptions: [],
            userOptions: [],
            fullCalendarStartTime: null,
            fullCalendarEndTime: null,
            fullShowNum: 1,
            fullShowList: [],
            timeLimitNum: 0,
            estimateTaskNum: 0,
            showLoading: true,
            taskMsgDialogVisible: false,
            showTaskItem: {},
            viewUserId: null,
            showChooseStatus: false,
            taskMediaChannelOptions: [],
            taskTimeLineOptions: [],
            taskCreateTypeOptions: [
                {
                    label: '任务',
                    value: 1,
                },
                {
                    label: '会议',
                    value: 2,
                },
            ],
            projectName: null,
            projectType: null,
            projectTypeOptions: [],
            projectSourceTypeOptions: [],
            projectSourceType: null,
            projectGroupOptions: [],
            groupId: null,
            haveProposalOptions: [
                {
                    label: "是",
                    value: 1,
                },
                {
                    label: "否",
                    value: 2,
                }
            ],
            haveProposal: 1,
            projectApprovalTime: null,
            summaryOfRequirements: null,
            projectInfo: null,
            clientsLinkIds: null,
            clientsLinkOptions: [],
            allUserOptions: [],
            leaderId: null,
            leaderName: "项目负责人",
            dialogVisibleChooseLeader: false,
            dialogVisibleChooseTeams: false,
            projectTeamsUsers: [],
            projectTeamsUsersChoose: [],
            projectListDialogVisible: false,
            joinUserList: [],
            powerList: [],
            leaderOptions: [],
            teamsOptions: [],
            checkAllTeams: false,
            checkTeams: false,
            isIndeterminate: false,
            showTeamUser: false,
            isIndeterminateUsers: false,
            checkAllTeamUsers: false,
            chooseTeamsIndex: null,
            teamUserList: [],
            holidayList: [],
            holidayChangeVersion: 1,
            updateProjectId: null,
            updateProjectStatus: false,
            updateProjectMsg: {},
            updateTaskId: null,
        }
    },
    created(){
        if(this.GetQueryString("local") == 1){
            localStorage.setItem("oa_token", "4cf22a7f-6395-4777-8ff4-1f9fbda99323")
            localStorage.setItem("oa_usermsg", '{"id":1,"gender":1,"avatar":"https://wework.qpic.cn/wwpic/147659_VD4dVpulSr2vQxb_1685352830/0","mobile":"15995070872","userType":2,"username":"朱慧勇","power":"user:addProject,user:addTask"}')
        }
        if (this.GetQueryString("local") == 2) {
            localStorage.setItem("oa_token", "ff15e203-ccf4-40ae-8e86-7c1d376a387d")
            localStorage.setItem("oa_usermsg", '{"id":30,"gender":1,"avatar":"http://wework.qpic.cn/wwhead/duc2TvpEgSTPk74IwG7Bs9RJUcRFib6ecpHhvYl8g8YoSq9YNLXzgoqv02KDvPIoKhq3XN77nfAQ/0","mobile":"","userType":1,"username":"陈黔","power":"user:addProject,user:addTask"}')
        }
        if(!localStorage.getItem("oa_token") && (localStorage.getItem("oa_local") && localStorage.getItem("oa_local") == 1)){
            localStorage.setItem("oa_token","4cf22a7f-6395-4777-8ff4-1f9fbda99323")
            localStorage.setItem("oa_usermsg", '{"id":1,"gender":1,"avatar":"https://wework.qpic.cn/wwpic/147659_VD4dVpulSr2vQxb_1685352830/0","mobile":"15995070872","userType":2,"username":"朱慧勇","power":"user:addProject,user:addTask"}')
        }
    },
    mounted() {
        this.drawerProject = false
        this.drawerTask = false
        this.dialogVisibleChooseLeader = false
        this.dialogVisibleChooseTeams = false
        this.showChooseStatus = false
        if (!localStorage.getItem("oa_version") || localStorage.getItem("oa_version") != 1) {
            localStorage.setItem("oa_version", 1)
            localStorage.removeItem("oa_token")
            localStorage.removeItem("oa_usermsg")
        }
        //如果没有token，并且url上没有code或state值，那么就需要授权登录
        if (!localStorage.getItem("oa_token") && (!this.GetQueryString("code") || !this.GetQueryString("state"))) {
            this.getAuthLoginUrl()
        }
        //如果有code，那么就调登录接口
        if (!localStorage.getItem("oa_token") && this.GetQueryString("code")) {
            this.getIndexApp(this.GetQueryString("code"))
        }
        let viewUserId = this.$route.query.viewUserId;
        if (viewUserId) {
            this.viewUserId = viewUserId
        }
        if (window.outerWidth > 750) {
            this.isMobile = false
            // 如果是手机上展示，就不需要左偏移
            var sheet = document.createElement('style');
            sheet.innerHTML = ".el-popper { left: 39% !important; }";
            document.body.appendChild(sheet);
        } else {
        }
        if(!this.viewUserId){
            if (localStorage.getItem("oa_usermsg")) {
                this.userMsg = JSON.parse(localStorage.getItem("oa_usermsg"))
            }
        }else{
            if (localStorage.getItem("oa_viewUserMsg")) {
                this.userMsg = JSON.parse(localStorage.getItem("oa_viewUserMsg"))
            }
        }
        if(this.userMsg && this.userMsg.power){
            this.powerList = this.userMsg.power.split(",")
        }
        if(this.fullCalendarStartTime && this.version == 1){
            this.version += 1
            this.getShowHoliday()
            this.getTaskList()
        }
        if(this.userMsg && !this.viewUserId){
            this.webSocketFun(this.userMsg.id)
        }
        this.getIndexNumber()
        document.addEventListener('click', this.hideShowChoose);
    },
    beforeDestroy() {
        document.removeEventListener('click', this.hideShowChoose);
    },
    methods: {
        goBack() {
            if(this.$router.history._startLocation == this.$route.fullPath){
                this.$router.push({
                    path: '/',
                    query: {}
                })
                location.reload()
            }else{
                this.$router.back(-1)
            }
        },
        doUpdateTask(item){
            this.projectId = item.projectId
            this.taskName = item.taskName
            this.taskType = item.taskType
            this.taskBackground = item.taskBackground
            this.taskInfo = item.taskInfo
            this.taskTarget = item.taskTarget
            this.taskStrategy = item.taskStrategy
            this.taskBigIdea = item.taskBigIdea
            this.taskCreative = item.taskCreative
            this.taskCreativeForm = item.taskCreativeForm
            this.taskRef = item.taskRef
            this.taskMediaChannel = item.taskMediaChannel
            this.taskTimeLine = item.taskTimeLine
            this.taskOther = item.taskOther
            this.taskCreateType = 1
            this.startTime = new Date(item.startTime)
            this.endTime = new Date(item.endTime)
            this.taskImportance = 4
            this.performerId = item.performerUsers
            this.showColor = parseInt(item.showColor)
            this.joinUserList = item.joinUsers
            this.updateTaskId = item.id
            this.getSysLabelValueListByColor()
            this.doGetProjectByAddTask()
            this.getSysLabelValueListByTaskType()
            this.getSysLabelValueListByTaskMediaChannel()
            this.getSysLabelValueListByTaskTimeLine()
            this.getUserList()
            this.drawerTask = true
        },
        delTaskItem(item){
            this.taskMsgDialogVisible = false
            location.reload()
            this.getTaskList()
        },
        doUpdateProjectItem(item){
            this.getSysLabelValueList()
            this.getSysLabelValueListByProjectSourceType()
            this.getSysLabelValueListByProjectGroup()
            this.getAllClientsLinkList()
            var teamUserList = []
            if(item.teamUserList && item.teamUserList.length > 0){
                item.teamUserList.forEach(teamUserItem => {
                    teamUserList.push({
                        label: teamUserItem.username,
                        value: teamUserItem.userId,
                    })
                })
            }
            this.projectTeamsUsers = teamUserList
            this.leaderId = item.projectLeader
            this.leaderName = item.leaderUserName
            var clientsLinkIds = []
            if (item.clientsLinkIds) {
                item.clientsLinkIds.split(",").forEach(clentItem => {
                    clientsLinkIds.push(clentItem * 1)
                })
            }
            this.clientsLinkIds = clientsLinkIds
            this.projectInfo = item.projectInfo
            this.projectName = item.projectName
            this.projectType = item.projectType
            this.projectSourceType = item.sourceType
            this.groupId = item.groupId
            this.haveProposal = item.haveProposal
            this.projectApprovalTime = new Date(item.projectApprovalTime)
            this.summaryOfRequirements = item.summaryOfRequirements
            this.updateProjectId = item.id
            this.updateProjectStatus = false
            this.drawerProject = true
        },
        getShowHoliday() {
            let that = this
            if (!localStorage.getItem("oa_token")) {
                return;
            }
            let year = this.fullCalendarStartTime.getFullYear()
            if(this.fullCalendarStartTime.getMonth() + 1 == 12){
                year = this.fullCalendarEndTime.getFullYear()
            }
            let params = {
                year: year,
                dayType: '2,3,4'
            };
            API.getHoliday(params).then(res => {
                if (res.key === 1000) {
                    var showList = []
                    var list = res.result.list
                    list.forEach(item => {
                    })
                    this.holidayList = list
                    this.holidayChangeVersion += 1
                } else {
                    this.$message({
                        type: "warning",
                        message: res.message
                    });
                }
            });
        },
        webSocketFun(id){
            var websocket = null;
            websocket = new WebSocket("wss://oa.mejour.cn/oa/app/webSocket/customWebSocketHandler?mchNo=" + id);
            //将消息显示在网页上  
            function setMessageInnerHTML(innerHTML) {
                console.log(innerHTML)
            }
            //关闭WebSocket连接  
            function closeWebSocket() {
                websocket.close();
            }
            //发送消息  
            function send() {
                var message = document.getElementById('text').value;
                websocket.send(message);
            }
            //连接发生错误的回调方法  
            websocket.onerror = function () {
                setMessageInnerHTML("WebSocket连接发生错误");
            };
            //连接成功建立的回调方法  
            websocket.onopen = function () {
                setMessageInnerHTML("WebSocket连接成功");
            }
            //接收到消息的回调方法  
            websocket.onmessage = function (event) {
                setMessageInnerHTML(event.data);
                if (event.data == "doRefreshTask") {
                    //刷新任务
                    alert("doRefreshTask")
                    this.getIndexNumber()
                    this.getTaskList()
                }
            }

            //连接关闭的回调方法  
            websocket.onclose = function () {
                setMessageInnerHTML("WebSocket连接关闭");
            }

            //监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常。  
            window.onbeforeunload = function () {
                closeWebSocket();
            }

        },
        doSureTeamUsers(){
            this.teamsOptions.forEach((item,index) => {
                if(index == this.chooseTeamsIndex){
                    item.userList = JSON.parse(JSON.stringify(this.teamUserList))
                }
            })
            this.updateAllChoose()
            this.showTeamUser = false
        },
        backTeams(){
            this.showTeamUser = false
        },
        handleCheckedTeamUsersChange(val) {
            let isIndeterminateUsers = false
            let checkAllTeamUsers = true
            let allChoose = true
            this.teamUserList.forEach(userItem => {
                if(!userItem.isChoose){
                    checkAllTeamUsers = false
                    allChoose = false
                }
                if(userItem.isChoose){
                    isIndeterminateUsers = true
                }
            })
            this.isIndeterminateUsers = isIndeterminateUsers
            this.checkAllTeamUsers = checkAllTeamUsers
            if(allChoose){
                this.isIndeterminateUsers = false
                this.checkAllTeamUsers = true
            }
        },
        handleCheckAllTeamUsersChange(val) {
            if (val) {
                this.teamUserList.forEach(item => {
                    item.isChoose = true
                })
                this.checkAllTeamUsers = true
                this.isIndeterminateUsers = false
            } else {
                this.teamUserList.forEach(item => {
                    item.isChoose = false
                })
                this.checkAllTeamUsers = false
                this.isIndeterminateUsers = false
            }
        },
        updateAllChoose(){
            this.teamsOptions.forEach(item => {
                let userAllChoose = true
                item.userList.forEach(userItem => {
                    if(!userItem.isChoose){
                        userAllChoose = false
                    }
                    if (userItem.isChoose) {
                        item.isAllChoose = true
                        item.isChoose = true
                    }
                })
                if(userAllChoose){
                    item.isAllChoose = false
                    item.isChoose = true
                }
            })
            let allChoose = true
            let allNoChoose = true
            this.teamsOptions.forEach(item => {
                if (!item.isChoose) {
                    allChoose = false
                }
                if (item.isChoose) {
                    allNoChoose = false
                }
            })
            this.checkAllTeams = allChoose
            this.isIndeterminate = !allChoose
            if (allNoChoose) {
                this.checkAllTeams = false
                this.isIndeterminate = false
            }
        },
        viewTeamUsers(item,index){
            this.isIndeterminateUsers = false
            this.checkAllTeamUsers = false
            this.showTeamUser = true
            this.chooseTeamsIndex = index
            this.teamUserList = JSON.parse(JSON.stringify(item.userList))

            let userAllChoose = true
            this.teamUserList.forEach(userItem => {
                if (!userItem.isChoose) {
                    userAllChoose = false
                }
                if (userItem.isChoose) {
                    this.isIndeterminateUsers = true
                    this.checkAllTeamUsers = true
                }
            })
            if (userAllChoose) {
                this.isIndeterminateUsers = false
                this.checkAllTeamUsers = true
            }
        },
        handleCheckedTeamsChange(val) {
            if(val){
                this.teamsOptions.forEach(item => {
                    if(item.isChoose){
                        item.userList.forEach(userItem => {
                            userItem.isChoose = true
                        })
                    }
                })
            }else{
                this.teamsOptions.forEach(item => {
                    if (!item.isChoose) {
                        item.userList.forEach(userItem => {
                            userItem.isChoose = false
                        })
                    }
                })
            }
            this.updateAllChoose()
        },
        handleCheckAllTeamsChange(val){
            if(val){
                this.teamsOptions.forEach(item => {
                    item.isChoose = true
                    item.userList.forEach(userItem => {
                        userItem.isChoose = true
                    })
                })
                this.checkAllTeams = true
                this.isIndeterminate = false
            }else{
                this.teamsOptions.forEach(item => {
                    item.isChoose = false
                    item.userList.forEach(userItem => {
                        userItem.isChoose = false
                    })
                })
                this.checkAllTeams = false
                this.isIndeterminate = false
            }
        },
        changeProjectDialogVisible(val){
            this.projectListDialogVisible = val
        },
        viewProjectList(){
            this.projectListDialogVisible = true
        },
        delTeamsUser(index,item){
            this.projectTeamsUsers.splice(index,1)
            // this.teamsOptions[item.teamsIndex].userList.forEach((userItem, userIndex) => {
            //     if(userItem.id == item.id){
            //         userItem.isChoose = false
            //     }
            // })
            // this.$refs.multipleTableTeams.toggleRowSelection(this.allUserOptions.find(row => {
            //     return row.value === item.value;
            // }), true);

        },
        selRowKey(row) {
            return row.value
        },
        doSureTeams(){
            let list = []
            this.teamsOptions.forEach((item,index) => {
                if(item.isChoose){
                    item.userList.forEach(userItem => {
                        if(userItem.isChoose){
                            list.push({
                                label: userItem.nickName,
                                value: userItem.id,
                                teamsIndex: index,
                            })
                        }
                    })
                }
            })
            this.projectTeamsUsers = list
            // this.projectTeamsUsers = this.projectTeamsUsersChoose
            if (this.projectTeamsUsers && this.projectTeamsUsers.length == 0) {
                this.$message({
                    type: "warning",
                    message: "请选择项目团队成员"
                });
                return;
            }
            // setTimeout(() => {
            //     this.projectTeamsUsersChoose.forEach(item => {
            //         this.$refs.multipleTableTeams.toggleRowSelection(item, false);
            //     })
            //     this.$refs.multipleTableTeams.clearSelection();
            // }, 0)
            this.dialogVisibleChooseTeams = false
            if (!this.isMobile) {
                setTimeout(() => {
                    var sheet = document.createElement('style');
                    sheet.innerHTML = ".el-popper { left: 39% !important; }";
                    document.body.appendChild(sheet);
                }, 51)
            }
        },
        setTeamsUsersSelect(){
            this.projectTeamsUsersChoose = this.projectTeamsUsers
            // if (this.projectTeamsUsersChoose && this.projectTeamsUsersChoose.length > 0) {
            //     if(this.allUserOptions && this.allUserOptions.length > 0){
            //         this.projectTeamsUsersChoose.forEach(item => {
            //             this.$refs.multipleTableTeams.toggleRowSelection(this.allUserOptions.find(row => {
            //                 return row.value === item.value;
            //             }), true);
            //         })
            //     }
            // }
        },
        handleSelectionChangeTeamsAll(e) {
            if(this.projectTeamsUsersChoose.length == this.allUserOptions.length){
                // 如果已全部选中，那么就设置为全部未选择
                this.projectTeamsUsersChoose = []
            }else{
                this.projectTeamsUsersChoose = this.allUserOptions
            }
        },
        handleSelectionChangeTeamsItem(e,selectItem) {
            if(this.projectTeamsUsersChoose.length > 0){
                var list = []
                this.projectTeamsUsersChoose.forEach(item => {
                    list.push(item.value)
                })
                if(list.indexOf(selectItem.value) == -1){
                    this.projectTeamsUsersChoose.push(selectItem)
                }
            }else{
                this.projectTeamsUsersChoose.push(selectItem)
            }
        },
        handleSelectionChangeTeams(e){
            console.log(e)
            this.projectTeamsUsersChoose = e
        },
        handleCloseChooseTeams(){
            this.dialogVisibleChooseTeams = false
            if (!this.isMobile) {
                setTimeout(() => {
                    var sheet = document.createElement('style');
                    sheet.innerHTML = ".el-popper { left: 39% !important; }";
                    document.body.appendChild(sheet);
                }, 51)
            }
        },
        goChooseTeams(){
            this.getAllUserList()
            setTimeout(()=>{
                this.setTeamsUsersSelect()
            },0)
            if (!this.isMobile) {
                var sheet = document.createElement('style');
                sheet.innerHTML = ".el-popper { left: 50% !important; }";
                document.body.appendChild(sheet);
            }
            this.dialogVisibleChooseTeams = true
        },
        doSureLeader(){
            if(!this.leaderId){
                this.$message({
                    type: "warning",
                    message: "请选择项目负责人"
                });
                return;
            }
            this.allUserOptions.forEach(item => {
                if(item.value == this.leaderId){
                    this.leaderName = item.label
                }
            })
            this.dialogVisibleChooseLeader = false
            if (!this.isMobile) {
                setTimeout(() => {
                    var sheet = document.createElement('style');
                    sheet.innerHTML = ".el-popper { left: 39% !important; }";
                    document.body.appendChild(sheet);
                }, 51)
            }
        },
        handleCloseChooseLeader(){
            this.dialogVisibleChooseLeader = false
            if (!this.isMobile) {
                setTimeout(() => {
                    var sheet = document.createElement('style');
                    sheet.innerHTML = ".el-popper { left: 39% !important; }";
                    document.body.appendChild(sheet);
                }, 51)
            }
        },
        goChooseLeader(){
            this.getAllUserList()
            if(!this.isMobile){
                var sheet = document.createElement('style');
                sheet.innerHTML = ".el-popper { left: 50% !important; }";
                document.body.appendChild(sheet);
            }
            this.dialogVisibleChooseLeader = true
        },
        // 所有人员列表
        getAllUserList() {
            if (!localStorage.getItem("oa_token")) {
                return;
            }
            let params = {};
            API.getAllUserList(params).then(res => {
                if (res.key === 1000) {
                    let userOptions = []
                    let leaderOptions = []
                    let list = res.result.list
                    list.forEach(item => {
                        userOptions.push({
                            label: item.nickName,
                            value: item.id
                        })
                        if (item.power && item.power.indexOf("user:addTask") > -1) {
                            leaderOptions.push({
                                label: item.nickName,
                                value: item.id
                            })
                        }
                    })
                    this.leaderOptions = leaderOptions
                    this.allUserOptions = userOptions
                    let teamJobUserTypeList = []
                    list.forEach(item => {
                        if(teamJobUserTypeList.indexOf(item.jobUserType) < 0){
                            teamJobUserTypeList.push(item.jobUserType)
                        }
                    })
                    var teamSortList = this.getListSort(teamJobUserTypeList)
                    var teamNameList = []
                    for(var i=0;i< teamSortList.length;i++){
                        let teamItem = teamSortList[i]
                        for(var j=0;j< list.length; j++){
                            let item = list[j]
                            if (teamItem == item.jobUserType) {
                                var obj = {
                                    jobUserType: item.jobUserType,
                                    jobUserTypeName: item.jobUserType == 0 ? '负责人' : item.jobUserTypeName,
                                    userList: [],
                                    isChoose: false,
                                    isAllChoose: false,
                                }
                                teamNameList.push(obj)
                                break
                            }
                        }
                    }
                    list.forEach(userItem => {
                        teamNameList.forEach(teamItem => {
                            if(userItem.jobUserType == teamItem.jobUserType){
                                userItem.isChoose = false
                                teamItem.userList.push(userItem)
                            }
                        })
                    })
                    this.teamsOptions = teamNameList
                    if(this.projectTeamsUsers && this.projectTeamsUsers.length > 0){
                        this.teamsOptions.forEach(item => {
                            item.userList.forEach(userItem => {
                                this.projectTeamsUsers.forEach(chooseItem => {
                                    if (userItem.id == chooseItem.value) {
                                        userItem.isChoose = true
                                    }
                                })
                            })
                        })
                        this.updateAllChoose()
                    }
                } else {
                    this.$message({
                        type: "warning",
                        message: res.message
                    });
                }
            });
        },
        getListSort(list){
            list.sort(function(a,b){
                return a-b;
            })
            return list;
        },
        getListSort2(list){
            var len = list.length
            for(var i = 0;i<len-1-i;i++){
                for(var j = 0;j<len-1-i;j++){
                    if(list[i]<list[i+1]){
                        var temp = list[j]
                        list[j] = list[j+1];
                        list[j+1] = temp;
                    }
                }
            }
            return list;
        },
        getNewProjectMsg(projectId) {
            let that = this
            if (!localStorage.getItem("oa_token")) {
                return;
            }
            let params = {
                projectId: projectId
            };
            API.getProjectList(params).then(res => {
                if (res.key === 1000) {
                    var list = res.result.list
                    if(list && list.length > 0){
                        list.forEach(item => {
                            if (item.createTime) {
                                item.createTimeString = that.$moment(item.createTime).format(
                                    "YYYY-MM-DD HH:mm:ss"
                                )
                            }
                            if (item.projectApprovalTime) {
                                item.projectApprovalTimeString = that.$moment(item.projectApprovalTime).format(
                                    "YYYY-MM-DD HH:mm:ss"
                                )
                            }
                            if (item.clientsLinkNames && item.clientsLinkNames.length > 0) {
                                let clientsLinkNamesString = ''
                                item.clientsLinkNames.forEach((clientsItem) => {
                                    clientsLinkNamesString = clientsLinkNamesString + clientsItem.projectName + "；"
                                })
                                item.clientsLinkNamesString = clientsLinkNamesString
                            }
                            if (item.teamUserList && item.teamUserList.length > 0) {
                                let teamUserNames = ''
                                item.teamUserList.forEach((teamItem) => {
                                    teamUserNames = teamUserNames + teamItem.username + "；"
                                })
                                item.teamUserNames = teamUserNames
                            }
                        })
                        this.updateProjectMsg = list[0]
                        this.updateProjectStatus = true
                    }
                } else {
                    this.$message({
                        type: "warning",
                        message: res.message
                    });
                }
            });
        },
        getAllClientsLinkList(){
            if (!localStorage.getItem("oa_token")) {
                return;
            }
            let params = {};
            API.getProjectList(params).then(res => {
                if (res.key === 1000) {
                    var list = res.result.list
                    list.forEach(item => {
                        item.label = item.projectName
                        item.value = item.id
                    })
                    this.clientsLinkOptions = list
                } else {
                    this.$message({
                        type: "warning",
                        message: res.message
                    });
                }
            });
        },
        getSysLabelValueListByProjectGroup() {
            if (!localStorage.getItem("oa_token")) {
                return;
            }
            let params = {
                sysLabelId: 8,
            };
            API.getSysLabelValueList(params).then(res => {
                if (res.key === 1000) {
                    var list = res.result.list
                    list.forEach(item => {
                        item.label = item.sysValueName
                        item.value = item.id
                    })
                    this.projectGroupOptions = list
                } else {
                    this.$message({
                        type: "warning",
                        message: res.message
                    });
                }
            });
        },
        getSysLabelValueListByProjectSourceType() {
            if (!localStorage.getItem("oa_token")) {
                return;
            }
            let params = {
                sysLabelId: 7,
            };
            API.getSysLabelValueList(params).then(res => {
                if (res.key === 1000) {
                    var list = res.result.list
                    list.forEach(item => {
                        item.label = item.sysValueName
                        item.value = item.id
                    })
                    this.projectSourceTypeOptions = list
                } else {
                    this.$message({
                        type: "warning",
                        message: res.message
                    });
                }
            });
        },
        // 修改任务类型后，清空已选择的执行人
        changeCreateType(){
            this.performerId = this.userMsg.id
            // if(this.taskCreateType && this.taskCreateType == 2){
            //     this.performerId = []
            // }else{
            //     this.performerId = null
            // }
        },
        // 投放周期列表
        getSysLabelValueListByTaskTimeLine() {
            if (!localStorage.getItem("oa_token")) {
                return;
            }
            let params = {
                sysLabelId: 6,
            };
            API.getSysLabelValueList(params).then(res => {
                if (res.key === 1000) {
                    var list = res.result.list
                    list.forEach(item => {
                        item.label = item.sysValueName
                        item.value = item.id
                    })
                    this.taskTimeLineOptions = list
                } else {
                    this.$message({
                        type: "warning",
                        message: res.message
                    });
                }
            });
        },
        // 投放渠道列表
        getSysLabelValueListByTaskMediaChannel() {
            if (!localStorage.getItem("oa_token")) {
                return;
            }
            let params = {
                sysLabelId: 5,
            };
            API.getSysLabelValueList(params).then(res => {
                if (res.key === 1000) {
                    var list = res.result.list
                    list.forEach(item => {
                        item.label = item.sysValueName
                        item.value = item.id
                    })
                    this.taskMediaChannelOptions = list
                } else {
                    this.$message({
                        type: "warning",
                        message: res.message
                    });
                }
            });
        },
        hideShowChoose(e){
            if(!this.viewUserId && this.powerList && this.powerList.length > 1){
                if (!this.$refs.box && !this.$refs.box.contains(e.target)) {
                    this.showChooseStatus = false;
                }
            }
        },
        showChoose(){
            this.showChooseStatus = !this.showChooseStatus
        },
        goTeamList(){
            this.$router.push({
                path: '/teamList',
            })
        },
        closeTaskMsgDialog(){
            this.taskMsgDialogVisible = false
        },
        chooseEventClick(e){
            this.showTaskItem = e
            this.taskMsgDialogVisible = true
        },
        getIndexNumber() {
            let that = this
            if (!localStorage.getItem("oa_token")) {
                return;
            }
            let params = {};
            API.getIndexNumber(params).then(res => {
                if (res.key === 1000) {
                    var map = res.result.data
                    this.timeLimitNum = map.timeLimitNum
                    this.estimateTaskNum = map.estimateTaskNum
                    this.showLoading = false
                } else {
                    this.$message({
                        type: "warning",
                        message: res.message
                    });
                }
            });
        },
        goNoticeList(){
            this.$router.push({
                path: '/noticeList',
            })
        },
        goTaskList(type){
            this.$router.push({
                path: '/taskList',
                query: {
                    type: type
                }
            })
        },
        getTaskList(){
            let that = this
            if (!localStorage.getItem("oa_token")) {
                return;
            }
            let params = {
                showStartTimeLong: this.fullCalendarStartTime.getTime(),
                showEndTimeLong: this.fullCalendarEndTime.getTime(),
            };
            if(this.viewUserId){
                params.performerId = this.viewUserId
            }
            API.getTaskList(params).then(res => {
                if (res.key === 1000) {
                    var showList = []
                    var list = res.result.list
                    list.forEach(item => {
                        item.title = item.taskName
                        item.start = that.$moment(item.startTime).format(
                            "YYYY-MM-DD HH:mm:ss"
                        )
                        item.end = that.$moment(item.endTime).format(
                            "YYYY-MM-DD HH:mm:ss"
                        )
                        item.color = item.showColorString
                        item.checkReadTaskStatus = false
                        let userMsg = JSON.parse(localStorage.getItem("oa_usermsg"))
                        if (item.performUserList && item.performUserList.length > 0) {
                            item.performUserList.forEach(userItem => {
                                if (userItem.taskUserId == userMsg.id && userItem.readStatus == 2) {
                                    item.checkReadTaskStatus = true
                                }
                            })
                        }
                        if (item.joinUserList && item.joinUserList.length > 0) {
                            item.joinUserList.forEach(userItem => {
                                if (userItem.taskUserId == userMsg.id && userItem.readStatus == 2) {
                                    item.checkReadTaskStatus = true
                                }
                            })
                        }
                        if (item.joinUserId) {
                            var joinUsers = []
                            item.joinUserId.split(",").forEach(subItem => {
                                joinUsers.push(subItem * 1)
                            })
                            item.joinUsers = joinUsers
                        }
                        if (item.performerId) {
                            var performerUsers = []
                            item.performerId.split(",").forEach(subItem => {
                                performerUsers.push(subItem * 1)
                            })
                            item.performerUsers = performerUsers
                        }
                        var fullObj =
                        {
                            taskMsg: item,
                            title: item.taskName,
                            start: that.$moment(item.startTime).format(
                                "YYYY-MM-DD HH:mm:ss"
                            ),
                            end: that.$moment(item.endTime).format(
                                "YYYY-MM-DD HH:mm:ss"
                            ),
                            color: item.showColorString,
                            editable: false,
                            overlap: false,
                        }
                        showList.push(fullObj)
                    })
                    this.fullShowList = showList
                    this.fullShowNum += 1
                    this.showLoading = false
                } else {
                    this.$message({
                        type: "warning",
                        message: res.message
                    });
                }
            });
        },
        fullCalendarTimeUpdate(startTime, endTime) {
            this.showLoading = true
            this.fullCalendarStartTime = startTime
            this.fullCalendarEndTime = endTime
            this.fullShowList = []
            this.fullShowNum += 1
            this.getShowHoliday()
            this.getTaskList()
        },
        // 获取fullCalendar的起始时间
        fullCalendarTimeGet(startTime, endTime){
            this.fullCalendarStartTime = startTime
            this.fullCalendarEndTime = endTime
        },
        // 人员列表
        getUserList() {
            if (!localStorage.getItem("oa_token")) {
                return;
            }
            let params = {};
            API.getUserList(params).then(res => {
                if (res.key === 1000) {
                    let userOptions = []
                    let leaderOptions = []
                    let list = res.result.list
                    list.forEach(item => {
                        userOptions.push({
                            label: item.nickName,
                            value: item.id
                        })
                        if(item.power && item.power.indexOf("user:addTask") > -1){
                            leaderOptions.push({
                                label: item.nickName,
                                value: item.id
                            })
                        }
                    })
                    this.leaderOptions = leaderOptions
                    this.userOptions = userOptions
                } else {
                    this.$message({
                        type: "warning",
                        message: res.message
                    });
                }
            });
        },
        // 显示任务类型
        getSysLabelValueListByTaskType() {
            if (!localStorage.getItem("oa_token")) {
                return;
            }
            let params = {
                sysLabelId: 4,
            };
            API.getSysLabelValueList(params).then(res => {
                if (res.key === 1000) {
                    var list = res.result.list
                    list.forEach(item => {
                        item.label = item.sysValueName
                        item.value = item.id
                    })
                    this.taskTypeOptions = list
                } else {
                    this.$message({
                        type: "warning",
                        message: res.message
                    });
                }
            });
        },
        // 添加任务中的项目列表
        doGetProjectByAddTask() {
            if (!localStorage.getItem("oa_token")) {
                return;
            }
            let params = {};
            API.getProjectList(params).then(res => {
                if (res.key === 1000) {
                    let projectOptions = []
                    let list = res.result.list
                    list.forEach(item => {
                        projectOptions.push({
                            label: item.projectName,
                            value: item.id
                        })
                    })
                    this.projectOptions = projectOptions
                } else {
                    this.$message({
                        type: "warning",
                        message: res.message
                    });
                }
            });
        },
        //添加任务
        doAddTask() {
            let that = this
            if (!localStorage.getItem("oa_token")) {
                return;
            }
            if (!this.projectId) {
                let msg = "请选择" + "客户"
                this.$message({
                    type: "warning",
                    message: msg
                });
                return
            }
            if (!this.taskType) {
                let msg = "请选择" + "项目阶段"
                this.$message({
                    type: "warning",
                    message: msg
                });
                return
            }
            if (!this.startTime) {
                this.$message({
                    type: "warning",
                    message: "请选择下单时间"
                });
                return
            }
            if (!this.endTime) {
                this.$message({
                    type: "warning",
                    message: "请选择提交时间"
                });
                return
            }
            if (!this.taskName) {
                let msg = "请输入" + "任务名称"
                this.$message({
                    type: "warning",
                    message: msg
                });
                return
            }
            if (!this.taskCreateType) {
                let msg = "请选择" + "任务类型"
                this.$message({
                    type: "warning",
                    message: msg
                });
                return
            }
            if (!this.taskImportance) {
                let msg = "请选择" + "任务重要程度"
                this.$message({
                    type: "warning",
                    message: msg
                });
                return
            }
            if (!this.taskBackground) {
                let msg = "请输入" + "背景"
                this.$message({
                    type: "warning",
                    message: msg
                });
                return
            }
            if (!this.taskInfo) {
                let msg = "请输入" + "项目资料"
                this.$message({
                    type: "warning",
                    message: msg
                });
                return
            }
            if (!this.showColor) {
                let msg = "请选择" + "展示颜色"
                this.$message({
                    type: "warning",
                    message: msg
                });
                return
            }
            if (!this.taskTarget) {
                let msg = "请输入" + "目标"
                this.$message({
                    type: "warning",
                    message: msg
                });
                return
            }
            if (!this.taskStrategy) {
                let msg = "请输入" + "策略"
                this.$message({
                    type: "warning",
                    message: msg
                });
                return
            }
            if (!this.taskBigIdea) {
                let msg = "请输入" + "沟通点"
                this.$message({
                    type: "warning",
                    message: msg
                });
                return
            }
            if (!this.taskCreative) {
                let msg = "请输入" + "核心玩法"
                this.$message({
                    type: "warning",
                    message: msg
                });
                return
            }
            if (!this.taskCreativeForm) {
                let msg = "请输入" + "创作形式"
                this.$message({
                    type: "warning",
                    message: msg
                });
                return
            }
            if (!this.taskRef) {
                let msg = "请输入" + "相关参考"
                this.$message({
                    type: "warning",
                    message: msg
                });
                return
            }
            if(!this.performerId || (typeof this.performerId === 'object' && this.performerId.length == 0)){
                this.$message({
                    type: "warning",
                    message: "请添加执行人"
                });
                return
            }
            let performerIds = this.performerId.join(",")
            let joinUserIds = null
            if(this.joinUserList && this.joinUserList.length > 0){
                joinUserIds = this.joinUserList.join(",")
            }
            let params = {
                projectId: this.projectId,
                taskName: this.taskName,
                taskType: this.taskType,
                startTime: this.startTime.getTime(),
                endTime: this.endTime.getTime(),
                taskImportance: this.taskImportance,
                performerId: performerIds,
                showColor: this.showColor,
                taskBackground: this.taskBackground,
                taskInfo: this.taskInfo,
                taskTarget: this.taskTarget,
                taskStrategy: this.taskStrategy,
                taskBigIdea: this.taskBigIdea,
                taskCreative: this.taskCreative,
                taskCreativeForm: this.taskCreativeForm,
                taskRef: this.taskRef,
                taskMediaChannel: this.taskMediaChannel,
                taskTimeLine: this.taskTimeLine,
                taskOther: this.taskOther,
                taskCreateType: this.taskCreateType,
                joinUserIds: joinUserIds
            };
            if(this.updateTaskId){
                params.taskId = this.updateTaskId
                API.doUpdateTaskApp(params).then(res => {
                    if (res.key === 1000) {
                        this.drawerTask = false
                        location.reload()
                        this.getTaskList()
                        // let colorString = ""
                        // this.colorOptions.forEach(item => {
                        //     if (item.id == params.showColor) {
                        //         colorString = item.sysValueName
                        //     }
                        // })
                        // let fullShowList = []
                        // fullShowList.push({
                        //     title: params.taskName,
                        //     start: that.$moment(params.startTime).format(
                        //         "YYYY-MM-DD HH:mm:ss"
                        //     ),
                        //     end: that.$moment(params.endTime).format(
                        //         "YYYY-MM-DD HH:mm:ss"
                        //     ),
                        //     color: colorString,
                        //     editable: false
                        // })
                        // this.fullShowList = fullShowList
                        this.fullShowNum += 1
                        this.$message({
                            type: "success",
                            message: "修改成功"
                        });
                    } else {
                        this.$message({
                            type: "warning",
                            message: res.message
                        });
                    }
                });
            }else{
                API.addTaskApp(params).then(res => {
                    if (res.key === 1000) {
                        this.drawerTask = false
                        location.reload()
                        let colorString = ""
                        this.colorOptions.forEach(item => {
                            if (item.id == params.showColor) {
                                colorString = item.sysValueName
                            }
                        })
                        let fullShowList = []
                        fullShowList.push({
                            title: params.taskName,
                            start: that.$moment(params.startTime).format(
                                "YYYY-MM-DD HH:mm:ss"
                            ),
                            end: that.$moment(params.endTime).format(
                                "YYYY-MM-DD HH:mm:ss"
                            ),
                            color: colorString,
                            editable: false
                        })
                        this.getTaskList()
                        this.fullShowList = fullShowList
                        this.fullShowNum += 1
                        this.$message({
                            type: "success",
                            message: "添加成功"
                        });
                    } else {
                        this.$message({
                            type: "warning",
                            message: res.message
                        });
                    }
                });
            }
        },
        //查询日历显示颜色
        getSysLabelValueListByColor() {
            if (!localStorage.getItem("oa_token")) {
                return;
            }
            let params = {
                sysLabelId: 3,
            };
            API.getSysLabelValueList(params).then(res => {
                if (res.key === 1000) {
                    var list = res.result.list
                    list.forEach(item => {
                        item.label = item.desc
                        item.value = item.id
                    })
                    this.colorOptions = list
                } else {
                    this.$message({
                        type: "warning",
                        message: res.message
                    });
                }
            });
        },
        //添加项目
        doAddProject(){
            if (!localStorage.getItem("oa_token")) {
                return;
            }
            if (!this.groupId) {
                let msg = "请选择" + "集团"
                this.$message({
                    type: "warning",
                    message: msg
                });
                return
            }
            if (!this.projectSourceType) {
                let msg = "请选择" + "来源类型"
                this.$message({
                    type: "warning",
                    message: msg
                });
                return
            }
            if (!this.projectType) {
                let msg = "请选择" + "项目类型"
                this.$message({
                    type: "warning",
                    message: msg
                });
                return
            }
            if (!this.projectName) {
                let msg = "请输入" + "项目名称"
                this.$message({
                    type: "warning",
                    message: msg
                });
                return
            }
            if (!this.haveProposal) {
                let msg = "请选择" + "是否提案&比稿"
                this.$message({
                    type: "warning",
                    message: msg
                });
                return
            }
            if (!this.projectApprovalTime) {
                let msg = "请选择" + "立项时间"
                this.$message({
                    type: "warning",
                    message: msg
                });
                return
            }
            if (!this.summaryOfRequirements) {
                let msg = "请输入" + "提案或执行要求概要"
                this.$message({
                    type: "warning",
                    message: msg
                });
                return
            }
            if (!this.projectInfo) {
                let msg = "请输入" + "项目资料"
                this.$message({
                    type: "warning",
                    message: msg
                });
                return
            }
            if (!this.leaderId) {
                let msg = "请选择" + "项目负责人"
                this.$message({
                    type: "warning",
                    message: msg
                });
                return
            }
            let teamUserIds = []
            if (!this.projectTeamsUsers || this.projectTeamsUsers.length == 0) {
                let msg = "请选择" + "项目团队"
                this.$message({
                    type: "warning",
                    message: msg
                });
                return
            }else{
                this.projectTeamsUsers.forEach(item => {
                    teamUserIds.push(item.value)
                })
            }
            let params = {
                teamUserIds: teamUserIds.join(","),
                leaderId: this.leaderId,
                projectInfo: this.projectInfo,
                projectName: this.projectName,
                projectType: this.projectType,
                sourceType: this.projectSourceType,
                groupId: this.groupId,
                haveProposal: this.haveProposal,
                projectApprovalTime: this.projectApprovalTime,
                summaryOfRequirements: this.summaryOfRequirements,
            };
            if(this.clientsLinkIds && this.clientsLinkIds.length > 0){
                params.clientsLinkIds = this.clientsLinkIds.join(",")
            }
            if(this.updateProjectId){
                params.projectId = this.updateProjectId
                API.updateProjectApp(params).then(res => {
                    if (res.key === 1000) {
                        this.updateProjectStatus = false
                        this.getNewProjectMsg(this.updateProjectId)
                        this.drawerProject = false
                        this.$message({
                            type: "success",
                            message: "修改成功"
                        });
                    } else {
                        this.$message({
                            type: "warning",
                            message: res.message
                        });
                    }
                });
            }else{
                API.addProjectApp(params).then(res => {
                    if (res.key === 1000) {
                        this.drawerProject = false
                        this.$message({
                            type: "success",
                            message: "添加成功"
                        });
                    } else {
                        this.$message({
                            type: "warning",
                            message: res.message
                        });
                    }
                });
            }
        },
        getSysLabelValueList(){
            if(!localStorage.getItem("oa_token")){
                return;
            }
            let token = localStorage.getItem("oa_token");
            let params = {
                token: token,
                sysLabelId: 2,
            };
            API.getSysLabelValueList(params).then(res => {
                if (res.key === 1000) {
                    var list = res.result.list
                    list.forEach(item => {
                        item.label = item.sysValueName
                        item.value = item.id
                    })
                    this.projectTypeOptions = list
                } else {
                    this.$message({
                        type: "warning",
                        message: res.message
                    });
                }
            });
        },
        hoverLogoutBtnClick(){
            localStorage.removeItem("oa_usermsg")
            localStorage.removeItem("oa_token")
            window.location.href = "https://oa.mejour.cn";
        },
        //点击添加任务事件
        hoverTaskBtnClick(){
            this.projectId = null
            this.taskName = null
            this.taskType = null
            this.taskBackground = null
            this.taskInfo = null
            this.taskTarget = null
            this.taskStrategy = null
            this.taskBigIdea = null
            this.taskCreative = null
            this.taskCreativeForm = null
            this.taskRef = null
            this.taskMediaChannel = null
            this.taskTimeLine = null
            this.taskOther = null
            this.taskCreateType = 1
            this.startTime = null
            this.endTime = null
            this.taskImportance = 4
            this.performerId = null
            this.showColor = null
            this.updateTaskId = null
            this.joinUserList = []
            this.getSysLabelValueListByColor()
            this.doGetProjectByAddTask()
            this.getSysLabelValueListByTaskType()
            this.getSysLabelValueListByTaskMediaChannel()
            this.getSysLabelValueListByTaskTimeLine()
            this.getUserList()
            this.drawerTask = true
        },
        //点击添加项目事件
        hoverProBtnClick(){
            this.getSysLabelValueList()
            this.getSysLabelValueListByProjectSourceType()
            this.getSysLabelValueListByProjectGroup()
            this.getAllClientsLinkList()
            this.projectTeamsUsers = []
            this.leaderId = null
            this.clientsLinkIds = []
            this.projectInfo = null
            this.projectName = null
            this.projectType = null
            this.projectSourceType = null
            this.groupId = null
            this.haveProposal = null
            this.projectApprovalTime = null
            this.summaryOfRequirements = null
            this.updateProjectId = null
            this.drawerProject = true
        },
        getIndexApp(code) {
            let params = {
                code: code
            };
            API.getIndexApp(params).then(res => {
                if (res.key === 1000) {
                    this.userMsg = res.result.data
                    localStorage.setItem("oa_usermsg", JSON.stringify(res.result.data))
                    localStorage.setItem("oa_token", res.result.token)
                    location.replace(location.origin)
                } else {
                    this.$message({
                        type: "warning",
                        message: res.message
                    });
                }
            });
        },
        //获取url上的参数
        GetQueryString(name) {
            var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
            var r = window.location.search.substr(1).match(reg); //获取url中"?"符后的字符串并正则匹配
            var context = "";
            if (r != null)
                context = decodeURIComponent(r[2]);
            reg = null;
            r = null;
            return context == null || context == "" || context == "undefined" ? "" : context;
        },
        getAuthLoginUrl() {
            let params = {};
            API.getAuthLoginUrl(params).then(res => {
                if (res.key === 1000) {
                    var url = res.result.data;
                    window.location.href = url;
                } else {
                    this.$message({
                        type: "warning",
                        message: res.message
                    });
                }
            });
        },
        getHealthCheck() {
            // let token = localStorage.getItem("oa_token");
            // if (!token) return;
            let params = {
                // token: localStorage.getItem("oa_token")
            };
            API.healthCheck(params).then(res => {
                if (res.key === 1000) {
                    this.$message({
                        type: "success",
                        message: "111222"
                    });
                } else {
                    this.$message({
                        type: "warning",
                        message: "2333"
                    });
                }
            });
        },
        visibleChangeProjectClientsLink(e) {
            if (e) {
                if (!this.isMobile) {
                    var sheet = document.createElement('style');
                    sheet.innerHTML = ".el-popper { left: 41% !important; }";
                    document.body.appendChild(sheet);
                }
            } else {
                if (!this.isMobile) {
                    setTimeout(() => {
                        var sheet = document.createElement('style');
                        sheet.innerHTML = ".el-popper { left: 39% !important; }";
                        document.body.appendChild(sheet);
                    }, 51)
                }
            }
        },
        visibleChangeProjectType(e) {
            if (e) {
                if (!this.isMobile) {
                    var sheet = document.createElement('style');
                    sheet.innerHTML = ".el-popper { left: 38% !important; }";
                    document.body.appendChild(sheet);
                }
            } else {
                if (!this.isMobile) {
                    setTimeout(() => {
                        var sheet = document.createElement('style');
                        sheet.innerHTML = ".el-popper { left: 39% !important; }";
                        document.body.appendChild(sheet);
                    }, 51)
                }
            }
        },
        visibleChangeTaskJoinUserId(e) {
            if (e) {
                if (!this.isMobile) {
                    var sheet = document.createElement('style');
                    sheet.innerHTML = ".el-popper { left: 36% !important; }";
                    document.body.appendChild(sheet);
                }
            } else {
                if (!this.isMobile) {
                    setTimeout(() => {
                        var sheet = document.createElement('style');
                        sheet.innerHTML = ".el-popper { left: 39% !important; }";
                        document.body.appendChild(sheet);
                    }, 51)
                }
            }
        },
        visibleChangeTaskPerformerId(e) {
            if (e) {
                if (!this.isMobile) {
                    var sheet = document.createElement('style');
                    sheet.innerHTML = ".el-popper { left: 36% !important; }";
                    document.body.appendChild(sheet);
                }
            } else {
                if (!this.isMobile) {
                    setTimeout(() => {
                        var sheet = document.createElement('style');
                        sheet.innerHTML = ".el-popper { left: 39% !important; }";
                        document.body.appendChild(sheet);
                    }, 51)
                }
            }
        },
        visibleChangeTaskMediaChannel(e) {
            if (e) {
                if (!this.isMobile) {
                    var sheet = document.createElement('style');
                    sheet.innerHTML = ".el-popper { left: 42% !important; }";
                    document.body.appendChild(sheet);
                }
            } else {
                if (!this.isMobile) {
                    setTimeout(() => {
                        var sheet = document.createElement('style');
                        sheet.innerHTML = ".el-popper { left: 39% !important; }";
                        document.body.appendChild(sheet);
                    }, 51)
                }
            }
        },
        visibleChangeTaskShowColor(e) {
            if (e) {
                if (!this.isMobile) {
                    var sheet = document.createElement('style');
                    sheet.innerHTML = ".el-popper { left: 41% !important; }";
                    document.body.appendChild(sheet);
                }
            } else {
                if (!this.isMobile) {
                    setTimeout(() => {
                        var sheet = document.createElement('style');
                        sheet.innerHTML = ".el-popper { left: 39% !important; }";
                        document.body.appendChild(sheet);
                    }, 51)
                }
            }
        },
        visibleChangeTaskEndTime(val) {
            if (val == 1) {
                if (!this.isMobile) {
                    var sheet = document.createElement('style');
                    sheet.innerHTML = ".el-popper { left: 41% !important; }";
                    document.body.appendChild(sheet);
                }
            } else {
                if (!this.isMobile) {
                    setTimeout(() => {
                        var sheet = document.createElement('style');
                        sheet.innerHTML = ".el-popper { left: 39% !important; }";
                        document.body.appendChild(sheet);
                    }, 51)
                }
            }
        },
        visibleChangeTaskImportance(e) {
            if (e) {
                if (!this.isMobile) {
                    var sheet = document.createElement('style');
                    sheet.innerHTML = ".el-popper { left: 43% !important; }";
                    document.body.appendChild(sheet);
                }
            } else {
                if (!this.isMobile) {
                    setTimeout(() => {
                        var sheet = document.createElement('style');
                        sheet.innerHTML = ".el-popper { left: 39% !important; }";
                        document.body.appendChild(sheet);
                    }, 51)
                }
            }
        },
        visibleChangeTaskCreateType(e) {
            if (e) {
                if (!this.isMobile) {
                    var sheet = document.createElement('style');
                    sheet.innerHTML = ".el-popper { left: 38% !important; }";
                    document.body.appendChild(sheet);
                }
            } else {
                if (!this.isMobile) {
                    setTimeout(() => {
                        var sheet = document.createElement('style');
                        sheet.innerHTML = ".el-popper { left: 39% !important; }";
                        document.body.appendChild(sheet);
                    }, 51)
                }
            }
        },
        visibleChangeProjectApprovalTime(val) {
            if (val == 1) {
                if (!this.isMobile) {
                    var sheet = document.createElement('style');
                    sheet.innerHTML = ".el-popper { left: 47% !important; }";
                    document.body.appendChild(sheet);
                }
            } else {
                if (!this.isMobile) {
                    setTimeout(() => {
                        var sheet = document.createElement('style');
                        sheet.innerHTML = ".el-popper { left: 39% !important; }";
                        document.body.appendChild(sheet);
                    }, 51)
                }
            }
        },
        visibleChangeSourceType(e) {
            if (e) {
                if (!this.isMobile) {
                    var sheet = document.createElement('style');
                    sheet.innerHTML = ".el-popper { left: 45% !important; }";
                    document.body.appendChild(sheet);
                }
            } else {
                if (!this.isMobile) {
                    setTimeout(() => {
                        var sheet = document.createElement('style');
                        sheet.innerHTML = ".el-popper { left: 39% !important; }";
                        document.body.appendChild(sheet);
                    }, 51)
                }
            }
        },
        visibleChangeGroup(e) {
            if (e) {
                if (!this.isMobile) {
                    var sheet = document.createElement('style');
                    sheet.innerHTML = ".el-popper { left: 35% !important; }";
                    document.body.appendChild(sheet);
                }
            } else {
                if (!this.isMobile) {
                    setTimeout(() => {
                        var sheet = document.createElement('style');
                        sheet.innerHTML = ".el-popper { left: 39% !important; }";
                        document.body.appendChild(sheet);
                    }, 51)
                }
            }
        },
    }
}
</script>

<style>
.el-date-picker__header-label{
    color: #ede6da;
}
.el-picker-panel__icon-btn{
    color: #ede6da;
}
.el-table__body-wrapper{
    background: #212121;
}
.el-loading-mask{
    background-color: #212121;
}
.el-table td.el-table__cell div{
    font-weight: bold;
    font-size: 13pt;
}
.el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell{
    background-color: #403e3e;
}
.el-table th.el-table__cell{
    background-color: #212121;
    color: #ede6da;
}
.el-table::before{
    height: 0px;
}
.el-table tr{
    background-color: #212121;
    color: #ede6da;
}
.el-transfer-panel .el-transfer-panel__header{
    background-color: #212121;
    color: #ede6da;
}
.el-transfer-panel{
    background-color: #212121;
    color: #ede6da;
}
.el-tag.el-tag--info{
    background-color: #ede6da;
}
.el-select-dropdown__item{
    color: #ede6da;
}
.el-select-dropdown.is-multiple .el-select-dropdown__item.selected.hover{
    background-color: #ede6da;
}
.el-select-dropdown.is-multiple .el-select-dropdown__item.selected{
    background-color: #ede6da;
    color: #409EFF;
}
.el-radio-group{
    margin: 10pt auto;
}
.el-time-spinner__item.hover, .el-time-spinner__item:hover{
    color: #ede6da;
}
.el-time-spinner__item.active:not(.disabled){
    color: #ede6da;
}
.el-time-spinner__item{
    color: #ede6da;
}
.el-button--mini{
    background-color: #212121;
    color: #ede6da;
}
.el-picker-panel__footer{
    background-color: #212121;
    color: #ede6da;
}
.el-select-dropdown__list{
    background-color: #212121;
    color: #ede6da;
}
.el-select-dropdown__item.hover, .el-select-dropdown__item:hover{
    background-color: #ede6da;
    color: #212121;
}
.el-textarea__inner{
    background-color: #212121;
    color: #ede6da;
}
.el-input__inner{
    background-color: #212121;
    color: #ede6da;
}
.el-input--suffix .el-input__inner{
    background-color: #212121;
    color: #ede6da;
    font-size: 10px;
}
.el-popper{
    
    background-color: #212121;
    color: #ede6da;
}
.el-date-picker.has-time .el-picker-panel__body-wrapper{
    width: 90%;
}
.xin-main .el-input{
    width: 50%;
}
.el-drawer{
    background-color: #212121;
    color: #ede6da;
}
.el-drawer__header{
    text-align: left;
}
.el-drawer__header span{
    height: 22pt;
    font-family: ChunkFive-Roman;
    color: #EDE6DA;
    font-size: 30.24pt;
}
.xin-footer{
    bottom: 5px;
    right: 10px;
    padding-bottom: 15px;
    padding-top: 20pt;
}
.el-dialog__title{
    color: white;
}
.el-dialog{
    background-color: #101010;
}
.el-card{
    background-color: #101010;
}
.el-card__body, .el-main{
    width: 90%;
    background-color: #101010;
    padding: 0 20pt 0 20pt
}
.el-avatar--large{
    width: 35pt;
    height: 35pt;
}
.fc .fc-toolbar-title{
    color: white;
    margin: 4pt auto;
    padding: 0pt 5pt;
}
#box-button{
    padding-top: 15pt;
}
.fc-theme-standard td, .fc-theme-standard th{
    border: 0px solid var(--fc-border-color);
}
.fc-theme-standard .fc-scrollgrid{
    border: 0px solid var(--fc-border-color);
    border-right: none;
    border-bottom: none;
}
.fc .fc-col-header-cell-cushion{
    float: left;
}
.fc .fc-daygrid-day-top{
    display: inline-block;
    height: 25pt;
}
.fc .fc-scroller{
    background-color: #101010;
}
.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events{
    min-height: 7em;
}
.fc-toolbar-chunk div{
    display: flex;
}
.fc .fc-button:focus{
    box-shadow: 0pt 0pt 0pt 0pt rgb(44 62 80 / 25%);
    android:elevation;
}
.fc .fc-button-primary:focus{
    box-shadow: 0pt 0pt 0pt 0pt rgb(44 62 80 / 25%);
}
.fc .fc-daygrid-day-number{
    position: unset;
}

.drawer-teams .el-checkbox-group{
    font-size: 20pt;
    margin: 15pt auto;
    display: inline-block;
}
.drawer-teams .el-checkbox__label{
    line-height: 20pt;
    font-size: 20pt;
}
.drawer-teams .team-view{
    display: inline-block;
    padding-left: 20pt;
    line-height: 20pt;
    font-size: 20pt;
    cursor: pointer;
    color: #606266;
}
.drawer-teams .drawer-team .team-view:hover{
    color: #EDE6DA;
}
.drawer-teams .drawer-team .back{
    height: 20pt;
    text-align: left;
    margin-left: 20pt;
    margin-top: 10pt;
}
.drawer-teams .drawer-team .back img{
    height: 20pt;
    width: 20pt;
    cursor: pointer;
}
</style>
<style scoped>
.drawer-teams-teams{
    margin: 15pt auto;
}
.fontClass{
    height: 100px;
}
.show-user{
    margin: -55px auto;
    width: 100%;
}
.user-text{
    font-size: 18.35pt;
    color: white;
    padding-left: 10pt;
}
.user-text-username{
    height: 17pt;
    font-size: 18.35pt;
    font-family: AlibabaPuHuiTi_2_105_Heavy;
    font-weight: 400;
    color: #FEFEF6;
    line-height: 21.1pt;
    display: flex;
}
.user-text-sex{
    padding-left: 10px;
}
.user-text-dep{
    display: inline-flex;
    padding-top: 7pt;
    height: 9.5pt;
    font-size: 9.4pt;
    font-family: AlibabaPuHuiTi_2_65_Medium;
    font-weight: 400;
    color: #FEFEF6;
    line-height: 16.98pt;
}
.user-text-tel{
    padding-left: 10px;
    height: 7.5pt;
    font-size: 9.4pt;
    font-family: AlibabaPuHuiTi_2_65_Medium;
    font-weight: 400;
    color: #FEFEF6;
    line-height: 16.98pt;
}
.drawer-project{
    font-size: 20px;
    display: flex;
    max-width: 750px;
}
.drawer-project-item{
    display: -webkit-inline-box;
    padding-top: 10pt;
    padding-left: 20px;
    float: left;
    text-align: left;
    width: 90%;
}
.drawer-project-item-title{
    height: 14pt;
    font-family: AlibabaPuHuiTi_2_55_Regular;
    font-size: 14.58pt;
    padding-top: 10pt;
}
.drawer-project-item-divider{
    border-bottom: 1pt solid #FBC600;
    margin: 14px 20px 5px 20px;
    width: 90%;
    float: left;
}
.drawer-project-item-name{
    margin: 15px 10px 15px 0;
    font-size: 12px;
}
.drawer-project-item-name-sub{
    color: #c0c4cc;
    font-size: 5.73pt;
}
.xin-main{
    position: relative;
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
}
.xin-content{
    overflow-y: scroll;
    box-sizing: border-box;
    overflow-x: hidden;
}
.person-button{
    width: 100%;
    display: inline-flex;
    margin: -15pt auto;
}
.person-button-item{
    margin: 10px auto;
}
.person-button-item-img{
    height: 50.8pt;
    width: 35.1pt;
    cursor: pointer;
}
.box-card{
    /* margin-top: 10px; */
    display: flex;
    overflow: hidden;
    border: none;
}
.box-card-left{
    float: left;
    display: flex;
}
.box-card-right{
    float: right;
}
.box-card-right-img{
    width: 41.5pt;
    height: 38.5pt;
    margin: 0 5pt;
}
.person-button-item-el{
    position: relative;
}
.person-button-item-el-right{
    position: absolute;
    right: 0;
    top: 0;
    background-color: red;
    width: 25%;
    height: 35%;
    border-radius: 90%;
}
.person-button-item-badge{
    /* margin-top: 10px;
    margin-right: 40px; */
}
.calendar{
    padding-top: 30pt;
}
.calendar-title{
    height: 30pt;
    width: 90%;
    color: #F0E7DA;
    font-size: 18.35pt;
    padding-left: 5%;
}
.calendar-title-image{
    font-size: 18.35pt;
    display: flex;
}
.calendar-title-image-left{
    padding-top: 20pt;
}
.calendar-title-image-left img{
    width: 30pt;
    height: 26pt;
}
.calendar-title-image-right{
    margin-left: auto;
    padding-top: 30pt;
    z-index: 999;
}
.calendar-title-image-right:hover{
    cursor: pointer;
}
.calendar-title-image-right img{
    width: 25.5pt;
    height: 17.5pt;
    z-index: 999;
}
.font{
    color: white;
    height: 25%;
    position:fixed;
    bottom:0;
    z-index:999;
    max-width: 750px;
    /* padding-left: 10pt; */
    width: 100%;
}
.font-botton{
    /* width: 100%; */
    float: right;
    margin: 20pt;
}
.font-botton-left{
    width: inherit;
    padding-top: 17.5pt;
}
.font-botton-left img{
    width: 36.5pt;
    height: 37.5pt;
    cursor: pointer;
}
.font-botton-center{
    padding-top: 7pt;
}
.font-botton-center img{
    width: 41.5pt;
    height: 54.5pt;
    cursor: pointer;
}
.font-botton-right{
    width: inherit;
    padding-top: 16pt;
}
.font-botton-right img{
    width: 29.5pt;
    height: 40pt;
    cursor: pointer;
}
.font-botton-choose{
    padding-top: 7pt;
}
.font-botton-choose img{
    width: 45pt;
    height: 45pt;
    cursor: pointer;
}

.font-button-choose{
    margin-top: -160pt;
    display: flex;
    width: 100%;
}
.font-button-choose-show{
    margin: auto;
    display: flex;
    border-radius: 25pt;
    padding: 10pt;
    background: white;
}
.font-button-choose-left{
    color: black;
}
.font-button-choose-left img{
    cursor: pointer;
}
.font-button-choose-right{
    margin-left: 10pt;
    color: black;
}
.font-button-choose-right img{
    cursor: pointer;
}
.drawer-project-item-user{
    width: 50%;
    text-align: center;
}
.drawer-project-item-user-name{
    display: inline-block;
    margin: 10pt auto;
    padding-left: 10pt;
}
.drawer-project-item-user-left{
    display: inline-flex;
    cursor: pointer;
}
.drawer-project-item-user-right{
    display: inline-flex;
    cursor: pointer;
}
.drawer-project-item-teams-content{
}
.drawer-project-item-teams-content-name{
    border: 1px solid #ede6da;
    margin: 10pt;
    text-align: center;
    display: inline-flex;
    min-width: 35pt;
}
.drawer-project-item-teams-content-name-show{
    margin: 5pt;
    cursor: pointer;
    font-size: 12px;
}

.back{
    height: 20pt;
    text-align: left;
    margin-left: 20pt;
    margin-top: -30pt;
}
.back img{
    height: 20pt;
    width: 20pt;
    cursor: pointer;
}
.drawer-project-item-teams-title{
    font-size: 12px;
}
.drawer-teams{
    font-size: 20px;
    display: flex;
    max-width: 750px;
}
</style>
