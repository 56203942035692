<template>
    <div class="FullCalendarModel">
        <FullCalendar  ref="myCalendar" :options="calendarOptions" />
    </div>
</template>
<script>
import FullCalendar from '@fullcalendar/vue'
import dayGrid from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
export default {
    name: 'MaintenanceCalendarview',
    components: {
        FullCalendar
    },
    props: ["type","fullShowNum","fullShowList","holidayList","holidayChangeVersion"],
    watch: {
        fullShowNum(){
            let calendarApi = this.$refs['myCalendar'].getApi()
            calendarApi.refetchEvents()
            if(this.fullShowList && this.fullShowList.length > 0){
                this.fullShowList.forEach(item => {
                    this.calendarOptions.events.push(item)
                })
            }else{
                this.calendarOptions.events = []
            }
        },
        holidayChangeVersion(){
            this.showHoliday()
        },
    },
    data() {
        return {
            loadingEvent: false,
            calendarOptions: {
                //duration 表示时间段 时间段越短 越排在前面
                //start 表示开始时间 开始时间越早 越排在前面
                //allDay 表示全天事件 全天事件优先排在前面
                //title 表示标题 标题首字符排序
                //此配置的优先顺序为：duration>start>allDay>title
                eventOrder: 'duration,start,allDay,title',
                // 引入的插件，比如fullcalendar/daygrid，fullcalendar/timegrid引入后才可显示月，周，日
                plugins: [dayGrid, interactionPlugin, timeGridPlugin, listPlugin],
                height: "auto",
                dragScroll: false,
                initialView: "dayGridMonth", // 默认为那个视图（月：dayGridMonth，周：timeGridWeek，日：timeGridDay）
                firstDay: 1, // 设置一周中显示的第一天是哪天，周日是0，周一是1，类推
                locale: "zh-cn", // 切换语言，当前为中文
                eventColor: "#101010", // 全部日历日程背景色
                themeSystem: "bootstrap", // 主题色(本地测试未能生效)
                initialDate: this.$moment().format("YYYY-MM-DD"), // 自定义设置背景颜色时一定要初始化日期时间
                timeGridEventMinHeight: "30", // 设置事件的最小高度
                aspectRatio: 1, // 设置日历单元格宽度与高度的比例。
                displayEventTime: true, // 是否显示时间
                allDaySlot: false, // 周，日视图时，all-day 不显示
                // eventLimit: true, // 设置月日程，与all-day slot的最大显示数量，超过的通过弹窗显示
                // unselectAuto: false, //当点击页面日历以外的位置时，是否自动取消当前的选中状态。
                headerToolbar: {
                    // 日历头部按钮位置
                    left: "",
                    center: "prev,title,next",
                    right: "",
                },
                buttonText: {
                    today: "今天",
                    month: "月",
                    week: "周",
                    day: "日",
                },
                slotLabelFormat: {
                    hour: "2-digit",
                    minute: "2-digit",
                    meridiem: false,
                    hour12: false, // 设置时间为24小时
                },
                // 事件
                datesSet: this.datesSet,
                customButtons: {
                    prev: {
                        click: () => {
                            this.prevWeekClick();
                        }
                    },
                    next: {
                        click: () => {
                            this.nextWeekClick();
                        }
                    }
                },
                dateClick: this.handleDateClick,
                eventClick: this.handleEventClick, // 点击日历日程事件
                eventDblClick: this.handleEventDblClick, // 双击日历日程事件 (这部分是在源码中添加的)
                eventClickDelete: this.eventClickDelete, // 点击删除标签事件 (这部分是在源码中添加的)
                eventDragStart: this.eventDragStart, // 拖动事件开始的时候
                eventDrop: this.eventDrop, // 拖动日历日程事件
                updateEvent: this.updateEvent,
                eventResize: this.eventResize, // 修改日历日程大小事件
                select: this.handleDateSelect, // 选中日历格事件
                eventDidMount: this.eventDidMount, // 安装提示事件
                // loading: false, // 视图数据加载中、加载完成触发（用于配合显示/隐藏加载指示器。）
                // selectAllow: this.selectAllow, //编程控制用户可以选择的地方，返回true则表示可选择，false表示不可选择
                eventMouseEnter: this.eventMouseEnter, // 鼠标滑过
                // allowContextMenu: false,
                editable: false, // 是否可以进行（拖动、缩放）修改
                eventStartEditable: false, // Event日程开始时间可以改变，默认true，如果是false其实就是指日程块不能随意拖动，只能上下拉伸改变他的endTime
                eventDurationEditable: false, // Event日程的开始结束时间距离是否可以改变，默认true，如果是false则表示开始结束时间范围不能拉伸，只能拖拽
                selectable: true, // 是否可以选中日历格
                dragRevertDuration: 500,
                selectMirror: true,
                selectMinDistance: 0, // 选中日历格的最小距离
                dayMaxEvents: false,
                weekends: true,
                slotDuration: "00:30:00", //一格时间槽代表多长时间，默认00:30:00（30分钟）
                moreLinkClassNames: 'more-btns',
                moreLinkContent: "查看更多",
                navLinks: false, // 天链接
                // selectHelper: false,
                slotEventOverlap: true, // 相同时间段的多个日程视觉上是否允许重叠，默认true允许
                // 后台返回的数据结构
                events: [
                    // {
                    //     title: '可以拖动但不能缩放',
                    //     start: '2023-07-01 12:30',
                    //     end: '2023-07-01 13:30',
                    // },//可以拖动但不能缩放，但在周、日视图中是可以进行缩放的
                    // {
                    //     title: '可以拖动、缩放',
                    //     start: '2023-07-02 00:00',
                    //     end: '2023-07-02 23:59',
                    //     color: 'red',
                    //     editable: true
                    // }, //可以拖动、缩放
                    // {
                    //     title: 'event 2',
                    //     start: '2023-07-02',
                    //     end: '2023-07-02',
                    //     color: 'red',
                    //     editable: true
                    // },
                    // {
                    //     title: 'event 1', date: '2023-07-01', classNames: ['cal'],
                    //     editable: true
                    // },
                    // {
                    //     start: '2023-07-24',
                    //     end: '2023-07-28',
                    //     overlap: false,
                    //     display: 'background',
                    //     color: '#ff9f89'
                    // },//背景色 (添加相同时间的背景色时颜色会重叠) 一点要初始化日期时间 initialDate: '2020-07-10',
                ],
            },
        }
    },
    mounted(){
        let calendarApi = this.$refs['myCalendar'].getApi()
        calendarApi.refetchEvents()
        let startTime = calendarApi.view.activeStart
        let endTime = calendarApi.view.activeEnd
        this.$emit('fullCalendarTime', startTime, endTime);
    },
    methods: {
        showHoliday(){
            var dayList = document.getElementsByClassName('fc-daygrid-day-number')
            Array.prototype.slice.call(dayList).forEach(e => {
                let label = e.getAttribute("aria-label")
                this.holidayList.forEach(holidayItem => {
                    if(label == holidayItem.dateName){
                        if (holidayItem.dayType == 4){
                            // 节假日-休息
                            let parentNode = e.parentNode
                            let holidayId = parentNode.getElementsByClassName("fc-holiday-css")
                            if (!holidayId || Array.prototype.slice.call(holidayId).length == 0) {
                                e.style.cssText += 'display: inline-flex;'
                                var li = document.createElement("div");
                                var text = document.createTextNode(holidayItem.holidayName);
                                li.className = 'fc-holiday-css'
                                li.appendChild(text)
                                li.style.cssText += 'color: green;margin: 0 auto;padding-left: 3pt;'
                                parentNode.appendChild(li);
                            }
                            let holiday3Id = e.getElementsByClassName("fc-holiday-right-css")
                            if (!holiday3Id || Array.prototype.slice.call(holiday3Id).length == 0) {
                                e.style.cssText += 'display: inline-flex;'
                                var li = document.createElement("div");
                                var text = document.createTextNode("休");
                                li.className = 'fc-holiday-right-css'
                                li.appendChild(text)
                                li.style.cssText += 'position: absolute;right: 0;color: green;'
                                e.appendChild(li);
                            }
                        } else if(holidayItem.dayType == 3){
                            // 周末休息
                            let holiday3Id = e.getElementsByClassName("fc-holiday-right-css")
                            if (!holiday3Id || Array.prototype.slice.call(holiday3Id).length == 0) {
                                e.style.cssText += 'display: inline-flex;'
                                var li = document.createElement("div");
                                var text = document.createTextNode("休");
                                li.className = 'fc-holiday-right-css'
                                li.appendChild(text)
                                li.style.cssText += 'position: absolute;right: 0;color: green;'
                                e.appendChild(li);
                            }
                        } else if (holidayItem.dayType == 2) {
                            // 调休-工作日
                            let holiday2Id = e.getElementsByClassName("fc-holiday-right-css")
                            if (!holiday2Id || Array.prototype.slice.call(holiday2Id).length == 0) {
                                e.style.cssText += 'display: inline-flex;'
                                var li = document.createElement("div");
                                var text = document.createTextNode("班");
                                li.className = 'fc-holiday-right-css'
                                li.appendChild(text)
                                li.style.cssText += 'position: absolute;right: 0;color: red;'
                                e.appendChild(li);
                            }
                        }
                    }
                })

                // if (label == '2023年9月1日' || label == '2023年8月1日') {
                //     let holidayId = e.getElementsByClassName("fc-holiday-css")
                //     if(!holidayId || Array.prototype.slice.call(holidayId).length == 0){
                //         e.style.cssText += 'display: inline-flex;'
                //         var li = document.createElement("div");
                //         var text = document.createTextNode("国庆");
                //         li.className = 'fc-holiday-css'
                //         li.appendChild(text)
                //         li.style.color = 'green'
                //         li.style.cssText += 'padding-left: 5pt;'
                //         e.appendChild(li);
                //     }
                // }
            })
        },
        // 上周点击
        prevWeekClick() {
            let calendarApi = this.$refs['myCalendar'].getApi();
            calendarApi.prev();
            const startTime = calendarApi.view.activeStart;
            const endTime = calendarApi.view.activeEnd;
            this.$emit('fullCalendarTimeUpdate', startTime, endTime);
            // this.$emit('clickPreNext', startTime, endTime)//拿到的日期格式传给父组件
        },
        // 下周点击
        nextWeekClick() {
            let calendarApi = this.$refs['myCalendar'].getApi();
            calendarApi.next();
            const startTime = calendarApi.view.activeStart;
            const endTime = calendarApi.view.activeEnd;
            this.$emit('fullCalendarTimeUpdate', startTime, endTime);
            // this.$emit('clickPreNext', startTime, endTime)
        },
        handleEventClick(e) {
            this.$emit("chooseEventClick", e.event.extendedProps.taskMsg)
        },
        handleDateClick(e) {
        },
        addFull(){
            //添加日程
            var fullObj = 
            {
                title: '添加事项添加事项添加事项添加事项添加事项添加事项添加事项添加事项添加事项添加事项添加事项添加事项添加事项',
                start: '2023-07-27',
                end: '2023-07-30',
                color: 'rgb(255, 159, 137)',
                editable: true
            }
            this.calendarOptions.events.push(fullObj)
        },
    }
}
</script>
<style scoped>
.FullCalendarModel{
    width: 90%;
    margin: auto;
    font-size: 0.1em;
}
</style>