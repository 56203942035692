<template>
    <div>
        <el-dialog title="项目列表" :visible.sync="projectListDialogVisible" :width="isMobile ? '100%' : '45%'" :before-close="handleClose">
            <el-table
                :data="projectList.filter(data => !searchKeyword || (data.projectName != null && data.projectName.indexOf(searchKeyword) > -1))"
                style="width: 100%"
                height="450"
            >
                <el-table-column
                    label="项目"
                    prop="projectName">
                </el-table-column>
                <el-table-column
                align="right">
                    <template slot="header" slot-scope="scope">
                        <el-input
                        v-model="searchKeyword"
                        size="mini"
                        placeholder="输入关键字搜索"/>
                    </template>
                    <template slot-scope="scope">
                        <el-button
                        size="mini"
                        @click="handleView(scope.row, scope.$index)">查看详情</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>
        <el-dialog title="项目详情" :visible.sync="projectMsgDialogVisible" :width="isMobile ? '100%' : '45%'" :before-close="handleCloseProjectMsgDialog">
            <ProjectModel @doUpdateItem="doUpdateItem" :item="projectMsg" :viewUserId="viewUserId" :isMobile="isMobile" :updateProjectStatus="updateProjectStatus" :updateProjectMsg="updateProjectMsg"></ProjectModel>
        </el-dialog>
    </div>
</template>
<script>
import ProjectModel from '@/components/ProjectModel';
import API from "@/api/api_index";

export default {
    name: 'project_msg_dialog',
    props: ["projectListDialogVisible", "viewUserId", "isMobile", "updateProjectStatus", "updateProjectMsg"],
    watch: {
        projectListDialogVisible(val){
            if(val){
                this.getAllProjectList()
            }
        },
        updateProjectStatus(val){
            if(val){
                console.log("------", this.updateProjectMsg)
                this.projectList[this.viewIndex] = this.updateProjectMsg
                this.projectMsg = this.updateProjectMsg
            }
        },
    },
    components: {
        ProjectModel,
    },
    data() {
        return {
            version: 1,
            dialogVisible: false,
            projectList: [],
            pageNum: 1,
            pageSize: 15,
            projectMsgDialogVisible: false,
            projectMsg: {},
            searchKeyword: null,
            viewIndex: null,
        }
    },
    methods: {
        doUpdateItem(item){
            this.$emit("doUpdateProjectItem", item)
        },
        doSearchList(){
            let list = this.projectList.filter(data => !searchKeyword || (data.projectName != null && data.projectName.indexOf(searchKeyword) > -1))
            return list
        },
        handleView(row, index){
            this.projectMsg = row
            this.viewIndex = index
            this.projectMsgDialogVisible = true
        },
        handleCloseProjectMsgDialog(){
            this.projectMsgDialogVisible = false
        },
        getAllProjectList() {
            let that = this
            if (!localStorage.getItem("oa_token")) {
                return;
            }
            let params = {};
            API.getProjectList(params).then(res => {
                if (res.key === 1000) {
                    var list = res.result.list
                    list.forEach(item => {
                        if(item.createTime){
                            item.createTimeString = that.$moment(item.createTime).format(
                                "YYYY-MM-DD HH:mm:ss"
                            )
                        }
                        if(item.projectApprovalTime){
                            item.projectApprovalTimeString = that.$moment(item.projectApprovalTime).format(
                                "YYYY-MM-DD HH:mm:ss"
                            )
                        }
                        if(item.clientsLinkNames && item.clientsLinkNames.length > 0){
                            let clientsLinkNamesString = ''
                            item.clientsLinkNames.forEach((clientsItem) => {
                                clientsLinkNamesString = clientsLinkNamesString + clientsItem.projectName + "；"
                            })
                            item.clientsLinkNamesString = clientsLinkNamesString
                        }
                        if (item.teamUserList && item.teamUserList.length > 0) {
                            let teamUserNames = ''
                            item.teamUserList.forEach((teamItem) => {
                                teamUserNames = teamUserNames + teamItem.username + "；"
                            })
                            item.teamUserNames = teamUserNames
                        }
                    })
                    this.projectList = list
                } else {
                    this.$message({
                        type: "warning",
                        message: res.message
                    });
                }
            });
        },
        handleClose() {
            this.$emit("changeProjectDialogVisible", false)
        }
    }
}
</script>
<style>
.el-button:focus, .el-button:hover{
    color: black;
    border-color: #FBC600;
    background-color: #FBC600;
}
</style>