import * as API from "./";

export default {
    healthCheck: params => {
        return API.POST("/oa/app/healthCheck.do", params);
    },
    //获取授权登录网页地址
    getAuthLoginUrl: params => {
        return API.GET("/oa/app/getAuthLoginUrl", params);
    },
    //授权登录获取用户详细信息
    getIndexApp: params => {
        return API.POST("/oa/app/getIndexApp", params);
    },
    //分类值列表
    getSysLabelValueList: params => {
        return API.POST("/oa/app/user/getSysLabelValueList", params);
    },
    // 获取节假日信息
    getHoliday: params => {
        return API.POST("/oa/app/user/getHoliday", params);
    },

    // 项目列表
    getProjectList: params => {
        return API.POST("/oa/app/user/getProjectList", params);
    },
    // 添加项目
    addProjectApp: params => {
        return API.POST("/oa/app/user/addProjectApp", params);
    },
    // 修改项目
    updateProjectApp: params => {
        return API.POST("/oa/app/user/updateProjectApp", params);
    },

    // 任务列表
    getTaskList: params => {
        return API.POST("/oa/app/user/getTaskList", params);
    },
    // 添加任务
    addTaskApp: params => {
        return API.POST("/oa/app/user/addTaskApp", params);
    },
    // 修改任务
    doUpdateTaskApp: params => {
        return API.POST("/oa/app/user/doUpdateTaskApp", params);
    },
    // 评估任务
    assessTask: params => {
        return API.POST("/oa/app/user/assessTask", params);
    },
    // 职能人员-提交后任务进入待审核
    sendTaskFile: params => {
        return API.POST("/oa/app/user/sendTaskFile", params);
    },
    // 审核提交的任务
    checkFileTask: params => {
        return API.POST("/oa/app/user/checkFileTask", params);
    },
    // 删除任务
    delTask: params => {
        return API.POST("/oa/app/user/delTask", params);
    },

    // 人员列表
    getUserList: params => {
        return API.POST("/oa/app/user/getUserList", params);
    },
    // 所有人员列表
    getAllUserList: params => {
        return API.POST("/oa/app/user/getAllUserList", params);
    },

    // 首页消息数量
    getIndexNumber: params => {
        return API.POST("/oa/app/user/getIndexNumber", params);
    },

    // 消息列表
    getNoticeList: params => {
        return API.POST("/oa/app/user/getNoticeList", params);
    },
    // 更新所有消息
    doUpdateNotice: params => {
        return API.POST("/oa/app/user/doUpdateNotice", params);
    },

    // 团队成员列表
    getUserTeamList: params => {
        return API.POST("/oa/app/user/getUserTeamList", params);
    },

}