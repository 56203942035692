<template>
    <div>
        <el-dialog
            title="任务详情"
            :visible.sync="taskMsgDialogVisible"
            width="100%"
            :before-close="handleClose">
            <TaskMsgCard :item="item" :viewUserId="viewUserId" @delItem="delItem" @doUpdateTask="doUpdateTask"></TaskMsgCard>
        </el-dialog>
    </div>
</template>
<script>
import TaskMsgCard from '@/components/TaskMsgCard';

export default {
    name: 'task_msg_dialog',
    props: ["taskMsgDialogVisible","item","viewUserId"],
    watch: {
    },
    components: {
        TaskMsgCard,
    },
    data() {
        return {
            version: 1,
            dialogVisible: false,
        }
    },
    methods: {
        handleClose(){
            this.$emit("closeTaskMsgDialog")
        },
        delItem(){
            this.$emit("delTaskItem",this.item)
        },
        doUpdateTask(){
            this.$emit("doUpdateTask", this.item)
        }
    }
}
</script>
<style>
</style>