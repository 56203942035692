<template>
    <div>
        <div class="back">
            <div>
                <img alt="" :src="require('@/assets/image/返回.png')" @click="goBack" />
            </div>
        </div>
        <div class="show-list" v-for="(item,index) in taskList" :key="index">
            <TaskMsgCard :item="item" @delItem="delItem(index)" :showType="2"></TaskMsgCard>
        </div>
        <div v-if="taskList && taskList.length > 0" style="height: 40px;"></div>
        <div v-if="!taskList || taskList.length <= 0">
            <el-empty image="https://oa.mejour.cn/logo/LOGO@2x.png" description="暂无数据"></el-empty>
        </div>
    </div>
</template>
<script>
import API from "@/api/api_index";
import TaskMsgCard from '@/components/TaskMsgCard';
export default {
    name: 'task_list',
    props: {},
    components: {
        TaskMsgCard,
    },
    data() {
        return {
            version: 1,
            startTime: new Date(new Date().getFullYear() + '/' + (new Date().getMonth() + 1) + '/' + new Date().getDate() + ' 00:00:00'),
            endTime: new Date(new Date().getFullYear() + '/' + (new Date().getMonth() + 1) + '/' + new Date().getDate() + ' 23:59:59'),
            taskList: [],
            statusType: 2,
        }
    },
    created() {
        this.statusType = this.$route.query.type * 1
        this.getTaskList()
    },
    mounted() {},
    methods: {
        goBack() {
            if (this.$router.history._startLocation == this.$route.fullPath) {
                this.$router.push({
                    path: '/',
                    query: {}
                })
                location.reload()
            } else {
                this.$router.back(-1)
            }
        },
        // 删除
        delItem(index){
            this.taskList.splice(index,1)
        },
        getTaskList(){
            let that = this
            if (!localStorage.getItem("oa_token")) {
                return;
            }
            let params = {
                showStartTimeLong: this.startTime.getTime(),
                showEndTimeLong: this.endTime.getTime(),
                statusType: this.statusType,
            };
            if(this.statusType == 1){
                params = {
                    statusType: this.statusType,
                };
            }
            API.getTaskList(params).then(res => {
                if (res.key === 1000) {
                    var list = res.result.list
                    list.forEach(item => {
                        item.title = item.taskName
                        item.start = that.$moment(item.startTime).format(
                            "YYYY-MM-DD HH:mm"
                        )
                        item.end = that.$moment(item.endTime).format(
                            "YYYY-MM-DD HH:mm"
                        )
                        if(item.finishTime){
                            item.finish = that.$moment(item.finishTime).format(
                                "YYYY-MM-DD HH:mm:ss"
                            )
                        }
                        item.color = item.showColorString
                        var taskImportanceName = '一般'
                        // 任务重要程度，1、特别紧急，2、紧急，3、较重，4、一般
                        if(item.taskImportance == 1){
                            taskImportanceName = '特别紧急'
                        } else if (item.taskImportance == 2) {
                            taskImportanceName = '紧急'
                        } else if (item.taskImportance == 3) {
                            taskImportanceName = '较重'
                        } else {
                            taskImportanceName = '一般'
                        }
                        item.taskImportanceName = taskImportanceName
                        item.checkReadTaskStatus = false
                        let userMsg = JSON.parse(localStorage.getItem("oa_usermsg"))
                        if (item.performUserList && item.performUserList.length > 0) {
                            item.performUserList.forEach(userItem => {
                                if (userItem.taskUserId == userMsg.id && userItem.readStatus == 2) {
                                    item.checkReadTaskStatus = true
                                }
                            })
                        }
                        if (item.joinUserList && item.joinUserList.length > 0){
                            item.joinUserList.forEach(userItem => {
                                if(userItem.taskUserId == userMsg.id && userItem.readStatus == 2){
                                    item.checkReadTaskStatus = true
                                }
                            })
                        }
                        if (item.joinUserId){
                            var joinUsers = []
                            item.joinUserId.split(",").forEach(subItem => {
                                joinUsers.push(subItem * 1)
                            })
                            item.joinUsers = joinUsers
                        }
                        if (item.performerId) {
                            var performerUsers = []
                            item.performerId.split(",").forEach(subItem => {
                                performerUsers.push(subItem * 1)
                            })
                            item.performerUsers = performerUsers
                        }

                    })
                    this.taskList = list
                } else {
                    this.$message({
                        type: "warning",
                        message: res.message
                    });
                }
            });
        }
    },
}
</script>
<style scoped>
.show-item{
    padding-top: 10px;
    width: 90%;
    margin: 1px auto;
}
.box-card {
}
.clearfix:before,
.clearfix:after {
    display: table;
    content: "";
}
.clearfix:after {
    clear: both
}
.card-show-color{
    list-style-type: circle;
    height: 10px;
    width: 10px;
}
.show-card-item{
    display: inline-flex;
    width: 100%;
    margin-top: 10px;
}
.show-card-item-left{
    font-size: 15px;
    font-weight: bold;
    width: 40%;
    text-align: left;
}
.show-card-item-right{
    width: 60%;
    text-align: left;
}
.back{
    height: 20pt;
    text-align: left;
    margin-left: 20pt;
    margin-top: -20pt;
}
.back img{
    height: 20pt;
    width: 20pt;
    cursor: pointer;
}
</style>