<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <!-- <home></home> -->
    <router-view></router-view>
  </div>
</template>

<script>
import home from './views/home/index.vue'

export default {
  name: 'app',
  components: {
    home,
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  font-size: 10px;
  background-color: #101010;
}
</style>
