import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';
import "../src/assets/style/reset.css"
import "../src/assets/font/font_f.css"
import moment from 'moment'
Vue.prototype.$moment = moment;
Vue.config.productionTip = false
Vue.use(ElementUI);

// 引入路由
import router from '@/router/index'

//引入组件库
import jvuewheel from '@jyeontu/jvuewheel'
//引入样式
import '@jyeontu/jvuewheel/lib/jvuewhell.css'
Vue.use(jvuewheel);

new Vue({
  //注册路由
  router,
  render: h => h(App),
}).$mount('#app')
