//1.导入VueRouter
import Vue from "vue";
import VueRouter from 'vue-router'

//2.使用路由
Vue.use(VueRouter);
// 把VueRouter原型对象push，保存一份
let originPush = VueRouter.prototype.push
let originReplace = VueRouter.prototype.replace

// 重写push|replace
// 第一个参数：告诉原来的push方法，往哪里跳转（传递哪些参数）
VueRouter.prototype.push = function (location, resolve, reject) {
    if (resolve && reject) {
        originPush.call(this, location, resolve, reject)
    } else {
        originPush.call(this, location, () => { }, () => { })
    }
}
VueRouter.prototype.replace = function (location, resolve, reject) {
    if (resolve && reject) {
        originReplace.call(this, location, resolve, reject)
    } else {
        originReplace.call(this, location, () => { }, () => { })
    }
}
// 页面
import home from '@/views/home/index'
import taskList from '@/views/taskList/taskList'
import noticeList from '@/views/noticeList/noticeList'
import teamList from '@/views/teamList/teamList'
import teamUserList from '@/views/teamUserList/teamUserList'


//3.创建VueRouter的实例
const router = new VueRouter({
    //tips:不想要 #（锚点）就添加下面代码
    mode: 'history',
    //4.配置路由的path和组件
    routes: [
        {
            path: "/",
            component: home,
            meta: { show: true }
        },
        {
            path: "/taskList",
            component: taskList,
            meta: { show: false }
        },
        {
            path: "/noticeList",
            component: noticeList,
            meta: { show: false }
        },
        {
            path: "/teamList",
            component: teamList,
            meta: { show: false }
        },
        {
            path: "/teamUserList",
            component: teamUserList,
            meta: { show: false }
        },
        // 重定向,在项目跑起来时，访问/，定向到/home
        {
            path: '*',
            redirect: "/"
        }
    ]
})
//5.导入路由实例
export default router
// ————————————————
// 版权声明：本文为CSDN博主「嚣张至极大大怪」的原创文章，遵循CC 4.0 BY - SA版权协议，转载请附上原文出处链接及本声明。
// 原文链接：https://blog.csdn.net/weixin_51390548/article/details/129325677